import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShield,
  faHouse,
  faHouseMedical,
  faHandshakeSimple,
  faStar,
  faUser,
  faHeart,
  faHandshakeAngle,
} from "@fortawesome/free-solid-svg-icons";

function switchIcon(slug) {
  let icon;

  switch (slug) {
    case "safety":
      icon = <FontAwesomeIcon icon={faShield} />;
      break;
    case "basic-needs":
      icon = <FontAwesomeIcon icon={faHouse} />;
      break;
    case "health-and-well-being":
      icon = <FontAwesomeIcon icon={faHouseMedical} />;
      break;
    case "connecting-safely":
      icon = <FontAwesomeIcon icon={faHandshakeSimple} />;
      break;
    case "claiming-your-power":
      icon = <FontAwesomeIcon icon={faStar} />;
      break;
    case "family":
      icon = <FontAwesomeIcon icon={faUser} />;
      break;
    case "saved":
      icon = <FontAwesomeIcon icon={faHeart} />;
      break;
    case "resources":
      icon = <FontAwesomeIcon icon={faHandshakeAngle} />;
      break;
    default:
      break;
  }
  return icon;
}

function PillarIcon(props) {
  // console.log( props );

  return <>{switchIcon(props.slug)}</>;
}

export default PillarIcon;
