import React, { useContext } from "react";
import { Button, Card } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

import "./ActivityQuizCard.scss";

import IhealContext from "../../Context/IhealContext";
import lang from "../../Assets/data/translations.json";

//activity card component, appears at the top of every pillar, has default activity atatched
function ActivityQuizCard(props) {
  const { parentCallback, title, time, color } = props;

  const iHeal = useContext(IhealContext);

  return (
    <div id="activity-quiz-card">
      <Card className="card">
        <div className="card-time">
          <FontAwesomeIcon icon={faClock} /> {time}
        </div>
        <div className="card-title">{title}</div>
        <Button
          className="rounded-button rounded-ends rounded-button-outline active-button full-width"
          style={{ color: `${color}` }}
          onClick={() => parentCallback("defaultActivity")}
        >
          {lang.common_start[iHeal.userInfo.language]}
        </Button>
      </Card>
    </div>
  );
}
export default ActivityQuizCard;
