import React, { useState, useEffect, useContext } from "react";
import { Page, Button, Carousel, CarouselItem } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import { getJson } from "../../GraphqlService.js";

import BottomBar from "../Homescreen/BottomBar";
import Pillar from "./Pillar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
//import SignUp from './SignUp';

import "./MultiItemList.scss";
import "./WordQuilt.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

// multi select activity- user is given question and selects multiple answers that apply, answers then all appear at end of activity
function WordQuilt(props) {
  // console.log( props );

  const { parentCallback, pillarItem, activityInfo, navigator, fromSaved } =
    props;

  const iHeal = useContext(IhealContext);

  const [carouselIndex, setCarouselIndex] = useState(1);

  const [numOfScreens, setNumOfScreens] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);

  // options selected on current screen
  const [buttonsSelected, setButtonsSelected] = useState([]);
  const [numOptionSelected, setNumOptionSelected] = useState(0);

  const [quizScore, setQuizScore] = useState(0);
  const [quizData, setQuizData] = useState();
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);

  const quizDataPath = activityInfo.activityData;
  const eventTracker = GaEventTracker(
    pillarItem.title_en + " > " + activityInfo.title_en,
  );

  //stores all options selected throughout activity, user gets shown list at end
  const [allOptions, setAllOptions] = useState([]);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getQuizData = async (path) => {
      try {
        getJson(path).then((res) => {
          if (isMounted) {
            setQuizData(res);
            setNumOfScreens(res.questions.length);
            setQuizDataLoaded(true);
          }
        });
      } catch (error) {
        console.error("WordQuilt getQuizData", error.message);
      }
    };
    getQuizData(quizDataPath);

    return () => {
      isMounted = false;
    };
  }, [quizDataPath]);

  const next = () => {
    var carousel2 = document.getElementById("carousel2");
    if (carouselIndex === numOfScreens) {
      // console.log("NO MORE QUESTIONS LEFT")
      setShowEndScreen(true);
      var scrollDiv = document.querySelector(".word-quilt .page__content");
      scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    } else {
      carousel2.next();
      return;
    }
  };

  const carouselUpdate = (e) => {
    var scrollDiv = document.querySelector(".word-quilt .page__content");
    scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setButtonsSelected([]);
    setNumOptionSelected(0);
    // console.log(e);
    setCarouselIndex(e.activeIndex + 1);
    // setAllOptionsBackup([...allOptions]);
    // console.log(allOptionsBackup);
  };

  const finishActivity = () => {
    // let myNavigator = document.querySelector('ons-navigator');
    // let pages = myNavigator.pages;

    //navigator.resetPage({component: Home}, {animation: 'fade'});
    // navigator.resetPage({component: Pillar, key: 'pillar-screen', props: {pillarName: pillarName, pillarIcon: pillarIcon, pillarColor: pillarColor}})
    navigator.resetPage({
      component: fromSaved ? Saved : Pillar,
      key: "pillar-screen",
      props: { pillarItem: pillarItem },
    });
  };

  const restartActivity = () => {
    let myNavigator = document.querySelector("ons-navigator");
    myNavigator.popPage();
  };

  const optionChosen = (buttonId, buttonValue, buttonText, question) => {
    if (buttonsSelected.includes(buttonId)) {
      /* OPTION UNCHECKED */
      eventTracker(question, "deselect " + buttonText, iHeal.userInfo.userid);

      setButtonsSelected((current) =>
        current.filter((btn) => {
          return btn !== buttonId;
        }),
      );
      setAllOptions((current) =>
        current.filter((btn) => {
          return btn !== buttonId;
        }),
      );
      setQuizScore(quizScore - 1);
      setNumOptionSelected(numOptionSelected - 1);
    } else {
      /* USER CLICK ON RESPONSE ITEM */
      eventTracker(question, buttonText, iHeal.userInfo.userid);
      setButtonsSelected((current) => [...current, buttonId]);
      setAllOptions((current) => [
        ...current,
        { id: buttonId, text: buttonText, colour: buttonValue },
      ]);

      setQuizScore(quizScore + 1);
      setNumOptionSelected(numOptionSelected + 1);
    }
  };

  const checkOptionDisabled = (wordId) => {
    if (numOptionSelected === 2 && !buttonsSelected.includes(wordId))
      return true;

    return false;
  };

  //cms needs to support variable number of questions, with each question having multiple possible answers. Each answer may also have a different weighting attached to it.
  function DisplayQuizData(quizData) {
    return (
      <Carousel
        fullscreen
        id="carousel2"
        onPostChange={(e) => carouselUpdate(e)}
      >
        {quizData &&
          quizData.questions.map((item) => {
            return (
              <CarouselItem key={item.id} className="carousel2-item">
                <p className="center">
                  {item[`title_${iHeal.userInfo.language}`]}
                </p>
                <br />
                <br />
                {item.answers.map((subitem) => {
                  return (
                    <Button
                      key={subitem.id}
                      className={`rounded-button rounded-ends rounded-button-outline full-width quiz-button ${
                        buttonsSelected.includes(subitem.id)
                          ? "active-button"
                          : ""
                      }`}
                      disabled={checkOptionDisabled(subitem.id)}
                      onClick={() =>
                        optionChosen(
                          subitem.id,
                          subitem.value,
                          subitem[`text_${iHeal.userInfo.language}`],
                          item.title_en,
                        )
                      }
                    >
                      {subitem[`text_${iHeal.userInfo.language}`]}
                    </Button>
                  );
                })}
              </CarouselItem>
            );
          })}
      </Carousel>
    );
  }

  function DisplayQuizResult(quizData, allOptions) {
    let allOptionsDisplay = "";
    const msgStart = quizData.results[0]["text_" + iHeal.userInfo.language];
    const msgEnd = quizData.results[1]["text_" + iHeal.userInfo.language];

    // console.log( allOptions );
    if (allOptions.length) {
      allOptions.forEach(
        (item) =>
          (allOptionsDisplay += `<Button class="rounded-button rounded-ends rounded-button-outline list-button" style="background-color: ${item.colour}; border-color: ${item.colour};">${item.text}</Button>`),
      );
    }

    return (
      <>
        <div
          key="msgStart"
          className="activity-description"
          dangerouslySetInnerHTML={{ __html: msgStart }}
        />
        <div
          key="display"
          id="word-quilt"
          className="activity-description"
          dangerouslySetInnerHTML={{ __html: allOptionsDisplay }}
        />
        <div
          key="msgEnd"
          className="activity-description"
          dangerouslySetInnerHTML={{ __html: msgEnd }}
        />
      </>
    );
  }

  function handleCallback(childData) {
    let screenName = childData;
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`word-quilt multi-item-list ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {!showEndScreen ? (
            <div style={{ width: "100%" }}>
              <div className="quiz-questions-label">
                {lang.activities_question[iHeal.userInfo.language]}{" "}
                {carouselIndex}/{numOfScreens}
                <br />
                <div className="full-width quiz-questions-progress-holder">
                  {/* <div className="quiz-questions-progress" style={{backgroundColor: pillarColor, width: ((carouselIndex/numOfScreens)*100) +"%"}} onClick={() => console.log(document.querySelector('ons-navigator').pages)}/> */}
                  <div
                    className="quiz-questions-progress"
                    style={{
                      backgroundColor: pillarItem.pillarColourHex,
                      width: (carouselIndex / numOfScreens) * 100 + "%",
                    }}
                    onClick={() =>
                      console.log(document.querySelector("ons-navigator").pages)
                    }
                  />
                </div>
              </div>

              {/* <Carousel fullscreen id="carousel2" onPostChange={(e) => carouselUpdate(e)}>
              {carouselItems}
            </Carousel> */}
              {quizDataLoaded ? (
                <div>{DisplayQuizData(quizData)}</div>
              ) : (
                <p>{lang.common_loading[iHeal.userInfo.language]}</p>
              )}

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                disabled={numOptionSelected === 2 ? false : true}
                onClick={() => next()}
              >
                {lang.common_next[iHeal.userInfo.language]}
              </Button>
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {DisplayQuizResult(quizData, allOptions)}

              <br />

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                onClick={() => finishActivity()}
              >
                {lang.activities_finish[iHeal.userInfo.language]}
              </Button>
              <Button
                className="rounded-button rounded-ends full-width no-outline-button"
                onClick={() => restartActivity()}
              >
                {lang.activities_redo[iHeal.userInfo.language]}
              </Button>
            </div>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default WordQuilt;
