import ReactGA from "react-ga4";

/*
    category = component name: Login, pillar name, Activity
    action = desc of what the user wants to do ie Pillar, Activity
    label = which one they're going in
    value = user id

    examples:
    Loading, Login
    Home, Pillar, Safety, user id
    Safety, Topic, Emergency Help, user id
*/
const GaEventTracker = (category = "category") => {
  const eventTracker = (action, label = "", value = "") => {
    if (value !== "") value = parseInt(value);
    // console.log( 'eventTracker', {category}, {action}, {label}, {value});

    if (window.location.href.indexOf("localhost") === -1) {
      if (value === "") {
        ReactGA.event({ category, action, label });
      } else {
        ReactGA.event({ category, action, label, value });
      }
    }
  };
  return eventTracker;
};
export default GaEventTracker;
