import React, { useState, useEffect, useContext } from "react";
import { Page, Button } from "react-onsenui";
import useCollapse from "react-collapsed";

import IhealContext from "../../Context/IhealContext";
import {
  createSavedItem,
  deleteSavedItem,
  itemAlreadySaved,
  getPillarTopics,
} from "../../GraphqlService";

import BottomBar from "../Homescreen/BottomBar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
//import SignUp from './SignUp';
import ResourcesList from "../Common/ResourcesList";

//multiple possible activity types
import WheelSlideshow from "./WheelSlideshow";
import MultiItemList from "./MultiItemList";
import WordQuilt from "./WordQuilt";
import ActivityIcon from "../Common/ActivityIcon";
import DropdownItem from "../Common/DropdownItem";

import "./ReadingActivity.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import SaveHeart from "../Common/SaveHeart";

//generic topic activity intro screen, with icon, title, description and start button
//basically slightly fancier version of defaultactivity.js
function ReadingActivity(props) {
  console.log(props);
  const { type, pillarItem, activityId, fromSaved } = props;

  const iHeal = useContext(IhealContext);

  const [topicsLoaded, setTopicsLoaded] = useState(false);
  const [activityInfo, setActivityInfo] = useState([]);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    try {
      getPillarTopics(pillarItem.id, activityId).then((res) => {
        if (isMounted) {
          console.log("gql", res);
          setActivityInfo(res && res[0] ? res[0] : undefined);
          setTopicsLoaded(true);
          console.log(
            "ReadingActivity getPillarTopics",
            res && res[0] ? res[0] : undefined,
          );
        }
      });
    } catch (error) {
      console.error("Pillar getPillarTopics", error.message);
    }

    return () => {
      isMounted = false;
    };
  }, [pillarItem, activityId, iHeal]);

  const pushPage = () => {
    //depending on what type the activity is, we navigate to different quiz-like/activity sections
    //select which activity to go to based on cms data

    // console.log( {pillarItem} );
    switch (type) {
      case "wheelActivity":
        props.navigator.pushPage({
          component: WheelSlideshow,
          key: "wheel-slideshow",
          props: { pillarItem: pillarItem, fromSaved },
        });
        break;
      case "multiItemListActivity":
        props.navigator.pushPage({
          component: MultiItemList,
          key: "multi-item-list",
          props: {
            pillarItem: pillarItem,
            activityInfo: activityInfo,
            fromSaved,
          },
        });
        break;
      case "wordQuilt":
        props.navigator.pushPage({
          component: WordQuilt,
          key: "word-quilt",
          props: {
            pillarItem: pillarItem,
            activityInfo: activityInfo,
            fromSaved,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleCallback = (childData) => {
    let screenName = childData;
    // console.log(childData);
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  };

  let contentBlocks = "";
  if (topicsLoaded && activityInfo && type === "basicReading") {
    contentBlocks = activityInfo[`articleBody_${iHeal.userInfo.language}`].map(
      (item) => {
        // console.log( item );

        if (item.typeHandle === "bodyContent") {
          return (
            <div
              key={item.id}
              className="pillar-subtitle"
              dangerouslySetInnerHTML={{ __html: item.bodyContent }}
            />
          );
        } else if (item.typeHandle === "bodyAccordion") {
          return (
            <DropdownItem
              key={item.id}
              title={item.accordionTitle}
              color={pillarItem.pillarColourHex}
            >
              <div className="flexbox">
                <div className="flex-15"></div>
                <div className="flex-70">
                  <div
                    key={item.id}
                    className="accordion-body pillar-subtitle"
                    dangerouslySetInnerHTML={{ __html: item.accordionContent }}
                  />
                </div>
                <div className="flex-15"></div>
              </div>
            </DropdownItem>
          );
        }

        return true;
      },
    );
  }

  async function toggleSavedArticle(isSaved) {
    if (isSaved) {
      // delete saved quote
      await deleteSavedItem(iHeal.userInfo.userid, activityInfo.id);
    } else {
      // create saved item
      await createSavedItem(iHeal.userInfo.userid, activityInfo.id, "topics");
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );
  return (
    <Page
      className={`reading-activity ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          <div className="header-container full-width">
            <div className="flexbox" style={{ alignItems: "center" }}>
              <div className="flex1 activitySave">
                <SaveHeart
                  checkSaved={() =>
                    itemAlreadySaved(iHeal.userInfo.userid, activityInfo.id)
                  }
                  toggleSaved={toggleSavedArticle}
                />
              </div>
            </div>
          </div>
          {topicsLoaded ? (
            type === "basicReading" ? (
              <div style={{ width: "100%" }}>
                <div className="image-container">
                  <ActivityIcon
                    title={activityInfo[`title_${iHeal.userInfo.language}`]}
                    icon={activityInfo.articleIcon[0].url}
                  />
                </div>

                <div className="center-text pillar-title">
                  {activityInfo[`title_${iHeal.userInfo.language}`]}
                </div>

                {contentBlocks}

                <ResourcesList filterBy="article" articleId={activityInfo.id} />

                <br />
                <br />

                <Button
                  className="rounded-button rounded-ends full-width"
                  style={{ color: pillarItem.pillarColourHex }}
                  onClick={() =>
                    document.querySelector("ons-navigator").popPage()
                  }
                >
                  {lang.common_goBack[iHeal.userInfo.language]}
                </Button>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div className="image-container">
                  <ActivityIcon
                    title={activityInfo[`title_${iHeal.userInfo.language}`]}
                    icon={activityInfo.articleIcon[0].url}
                  />
                </div>

                <div className="center-text pillar-title">
                  {activityInfo[`title_${iHeal.userInfo.language}`]}
                </div>

                {/* {type === "wheelActivity" &&
                <div className='big-image-container'>
                  <img className="signup-image" alt="Special Activity" src={Wheel}/>
                </div>
              } */}

                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      activityInfo[
                        `activityDescription_${iHeal.userInfo.language}`
                      ],
                  }}
                />

                <br />
                <br />

                <Button
                  className="rounded-button rounded-ends full-width"
                  style={{ color: pillarItem.pillarColourHex }}
                  onClick={() => pushPage()}
                >
                  {lang.common_start[iHeal.userInfo.language]}
                </Button>
              </div>
            )
          ) : (
            <div>Loading...</div>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default ReadingActivity;
