import React, { useState } from "react";

import IhealContext from "./IhealContext";

const defaultValues = {
  userInfo: {
    userid: "",
    language: "",
    province: "",
  },
};

function IhealProvider(props) {
  // console.log( props );

  const [userInfo, setUserInfo] = useState(props || defaultValues);

  return (
    <IhealContext.Provider value={{ userInfo: userInfo, setUserInfo }}>
      {props.children}
    </IhealContext.Provider>
  );
}

export default IhealProvider;
