import React, { useState, useEffect, useContext } from "react";
import { Page, Input, Button, Checkbox, Modal } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faKey,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext";

import "./SignUp.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import Info from "./Info";

import {
  hashString,
  usernameExists,
  createUser,
  getDefaultBySlug,
} from "../../GraphqlService";

//page handling the signup elements
//todo: hook up to backend user database
function SignUp(props) {
  const eventTracker = GaEventTracker("Sign Up");

  const { userInfo, setUserInfo } = useContext(IhealContext);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState(null);
  const [username, setusername] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [usernameAvailable, setUsernameAvailable] = useState(true);

  const [language, setLanguage] = useState(null);
  const [province, setProvince] = useState(null);
  const [userType, setUserType] = useState(null);

  const [defaultContent, setDefaultContent] = useState("");
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    setLanguage(localStorage.getItem("language"));
    setProvince(localStorage.getItem("location"));
    setUserType(localStorage.getItem("userType"));

    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getContent = async () => {
      try {
        getDefaultBySlug("terms-conditions").then((res) => {
          if (isMounted) {
            setDefaultContent(res);
            setContentLoaded(true);
          }
        });
      } catch (error) {
        console.error("SignUp getDefaultBySlug", error.message);
      }
    };
    getContent();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleClick = () => {
    const newUser = {
      username: hashString(username),
      password: hashString(password),
      language: language,
      province: province,
      userType: userType,
    };
    // console.log( newUser );

    try {
      usernameExists(newUser.username).then((res) => {
        // console.log( res );
        if (res === true) {
          eventTracker("fail", "username unavailable");
          setUsernameAvailable(false);
        } else {
          try {
            setUsernameAvailable(true);
            createUser(newUser).then((res) => {
              const userInfo = {
                userid: res,
                language: newUser.language,
                province: newUser.province,
                userType: newUser.userType,
                showTour: true,
              };
              // console.log( userInfo );
              setUserInfo(userInfo);
              eventTracker("success", "", userInfo.userid);
              props.navigator.pushPage({
                component: Info,
                props: { key: "info-screen", userInfo: userInfo },
              });
            });
          } catch (error) {
            console.error("SignUp createUser", error.message);
          }
        }
      });
    } catch (error) {
      console.error("SignUp usernameExists", error.message);
    }
  };

  return (
    <Page className="signup">
      <div className="page-container-100 scroll-overflow">
        <div className="flex-scroll">
          <Header type="accountCreation" lang={language} />

          <div className="page-container-40">
            <div className="flexbox">
              <div className="flexside flex-20"></div>
              <div className="flexside flex-60 flex-center center jc-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: lang.signup_title[language],
                  }}
                />
              </div>
              <div className="flexside flex-20"></div>
            </div>
          </div>

          <div className="page-container-40">
            <div className="flexbox">
              <div className="flexside flex-20"></div>
              <div className="flexside flex-60 flex-center jc-center">
                {usernameAvailable ? (
                  <div></div>
                ) : (
                  <div
                    style={{ color: "#ff2f2f", marginBottom: "30px" }}
                    dangerouslySetInnerHTML={{
                      __html: lang.signup_usernameUnavilable[language],
                    }}
                  />
                )}

                <div className="input-field">
                  <FontAwesomeIcon icon={faUser} />
                  <Input
                    value={username}
                    onInput={(e) => setusername(e.target.value)}
                    modifier="material"
                    placeholder={lang.signup_placeholderUsername[language]}
                  />
                </div>
                <div className="input-field">
                  <FontAwesomeIcon icon={faKey} />
                  <Input
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                    modifier="material"
                    placeholder={lang.signup_placeholderPassword[language]}
                    type="password"
                  />
                </div>
                <div className="input-field">
                  <FontAwesomeIcon icon={faKey} />
                  <Input
                    value={password2}
                    onInput={(e) => setPassword2(e.target.value)}
                    modifier="material"
                    placeholder={
                      lang.signup_placeholderConfirmPassword[language]
                    }
                    type="password"
                  />
                </div>

                <div>{lang.signup_passwordMessage[language]}</div>
              </div>
              <div className="flexside flex-20"></div>
            </div>
          </div>

          <div className="page-container-10">
            <div className="flexbox">
              <div className="flexside flex-20"></div>
              <div className="flexbox flex-60 flex-center jc-center jc-spacebetween">
                <Checkbox
                  inputId="terms"
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  modifier="material"
                />
                <label for="terms">{lang.signup_terms[language]}</label>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  onClick={() => setModalOpen(!modalOpen)}
                />
              </div>
              <div className="flexside flex-20"></div>
            </div>
          </div>

          <div className="page-container-20">
            {/* Use onsen default button
          then make custom buttons in separate style sheet*/}

            <div className="flexbox">
              <div className="flexside flex-20"></div>
              <div className="flexside flex-60 flex-center jc-center">
                <Button
                  className="rounded-button rounded-ends full-width"
                  disabled={
                    !(
                      termsAccepted &&
                      password === password2 &&
                      password.length >= 8 &&
                      username.length > 1
                    )
                  }
                  onClick={() => handleClick()}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: lang.common_next[language],
                    }}
                  />
                </Button>
              </div>
              <div className="flexside flex-20"></div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClick={() => setModalOpen(!modalOpen)}
        style={{ alignItems: "center" }}
        animation="fade"
      >
        <div
          className="tandc"
          style={{
            width: "80%",
            height: "100%",
            backgroundColor: "#ffffff",
            color: "#222222",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "left",
            padding: "1rem",
            boxSizing: "border-box",
          }}
        >
          <br />
          {contentLoaded ? (
            <div
              dangerouslySetInnerHTML={{
                __html: defaultContent["body_" + language],
              }}
              style={{ overflow: "scroll", height: "80vh", minHeight: 480 }}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </Modal>
    </Page>
  );
}
export default SignUp;
