import React from "react";
import { Collapse } from "react-collapse";
import { Button, Icon } from "react-onsenui";

import "./DropdownItem.scss";

//collapsible type dropbox for activity screens
class DropdownItem extends React.Component {
  state = {
    isDropdownOpen: false,
    dropdownToggleIcon: "+",
  };

  onDropdownClicked = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      dropdownToggleIcon: prevState.dropdownToggleIcon === "+" ? "-" : "+",
    }));
  };

  render() {
    return (
      <div id={this.props.id}>
        <div>
          <Button
            onClick={this.onDropdownClicked}
            className={`accordion rounded-button rounded-button-outline full-width dropdown-item`}
            style={{ marginBottom: this.state.isDropdownOpen ? "0" : "10px" }}
          >
            <div className="flexbox" style={{ alignItems: "stretch" }}>
              <div
                className="cheveron flexside ai-center jc-center flex-10"
                style={{ backgroundColor: this.props.color }}
              >
                <Icon
                  icon={
                    !this.state.isDropdownOpen
                      ? `fa-chevron-right`
                      : `fa-chevron-down`
                  }
                  size={20}
                  fixedWidth={false}
                  style={{ verticalAlign: "middle", zIndex: 10 }}
                />
              </div>
              <div
                className="heading flexside jc-center flex-90 flex-left"
                style={{
                  textAlign: "left",
                  backgroundColor: this.state.isDropdownOpen
                    ? "#dfdfe0"
                    : "#F2F2F2",
                }}
              >
                <div>{this.props.title}</div>
              </div>
            </div>
          </Button>
        </div>
        <Collapse isOpened={this.state.isDropdownOpen}>
          <>{this.props.children}</>
        </Collapse>
      </div>
    );
  }
}

export default DropdownItem;

//Light Theme
