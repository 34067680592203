import React from "react";
import { Page, Button } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";

import "./Loading.scss";

import Header from "../Common/Header";

// import Location from './Location';
import UserType from "./UserType";

//language page, user selects language
//option should be sent to CMS to pull appropriate content for rest of app
export default class Loading extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLanguageSelected: false,
      languageChoice: null,
    };
  }

  pushPage() {
    const eventTracker = GaEventTracker("Language");
    eventTracker(this.state.languageChoice);
    localStorage.setItem("language", this.state.languageChoice);
    this.props.navigator.pushPage({
      component: UserType,
      key: "usertype-screen",
    });
  }

  setLanguage(lang) {
    this.setState({
      isLanguageSelected: true,
      languageChoice: lang,
    });
  }
  render() {
    return (
      <Page className="signup language">
        <div className="page-container-100 scroll-overflow">
          <div className="flex-scroll">
            <Header type="accountCreation" lang="en" />

            <div className="page-container-30">
              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center center">
                  <h1>
                    Select your <b>language</b>
                  </h1>
                  <h1>
                    Sélectionnez votre <b>langue</b>
                  </h1>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-60">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center">
                  <Button
                    className={`rounded-button rounded-ends rounded-button-outline rounded-ends full-width ${this.state.languageChoice === "en" ? "active-button" : ""}`}
                    onClick={() => this.setLanguage("en")}
                  >
                    English
                  </Button>
                  <Button
                    className={`rounded-button rounded-ends rounded-button-outline rounded-ends full-width ${this.state.languageChoice === "fr" ? "active-button" : ""}`}
                    onClick={() => this.setLanguage("fr")}
                  >
                    Français
                  </Button>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-10">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center">
                  <Button
                    className="rounded-button rounded-ends full-width"
                    disabled={!this.state.isLanguageSelected}
                    onClick={this.pushPage.bind(this)}
                  >
                    Next
                  </Button>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
