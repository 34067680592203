import React from "react";
import { Page, Button } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";

import "./Location.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import Location from "./Location";

export default class UserType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: "",
      language: localStorage.getItem("language"),
      windowHeight: window.innerHeight,
    };
  }

  //resize code helps make the scroll box have a bit more of a responsive design to different sized screens
  handleResize() {
    this.setState({
      windowHeight: window.innerHeight,
    });
    console.log("windwo resize: " + this.state.windowHeight);
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.handleResize());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize());
  }

  pushPage() {
    const eventTracker = GaEventTracker("User Type");
    eventTracker(this.state.userType);
    this.props.navigator.pushPage({
      component: Location,
      key: "location-screen",
    });
  }

  handleClick(data) {
    // console.log(data);

    this.setState({
      userType: data,
    });
    var userType = data;

    if (!this.state.onboardedUser) {
      localStorage.setItem("userType", userType);
    }
  }

  render() {
    return (
      <Page className="location-screen usertype">
        <div className="page-container-100 scroll-overflow">
          <div className="flex-scroll">
            <Header type="accountCreation" lang={this.state.language} />

            <div className="page-container-30">
              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center center jc-center">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: lang.userType_title[this.state.language],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: lang.userType_intro[this.state.language],
                    }}
                  />
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-60">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center jc-center">
                  <div className="location-container">
                    <Button
                      className={`rounded-button rounded-button-outline full-width ${this.state.userType === "yourself" ? "active-button" : ""}`}
                      id="userType1"
                      onClick={() => this.handleClick("yourself")}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang.userType_yourself[this.state.language],
                        }}
                      />
                    </Button>
                    <Button
                      className={`rounded-button rounded-button-outline full-width ${this.state.userType === "familyFriend" ? "active-button" : ""}`}
                      id="userType2"
                      onClick={() => this.handleClick("familyFriend")}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            lang.userType_familyFriend[this.state.language],
                        }}
                      />
                    </Button>
                    <Button
                      className={`rounded-button rounded-button-outline full-width ${this.state.userType === "serviceProvider" ? "active-button" : ""}`}
                      id="userType3"
                      onClick={() => this.handleClick("serviceProvider")}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            lang.userType_serviceProvider[this.state.language],
                        }}
                      />
                    </Button>
                    <Button
                      className={`rounded-button rounded-button-outline full-width ${this.state.userType === "generalInterest" ? "active-button" : ""}`}
                      id="userType4"
                      onClick={() => this.handleClick("generalInterest")}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            lang.userType_generalInterest[this.state.language],
                        }}
                      />
                    </Button>
                  </div>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-10">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center jc-center">
                  <Button
                    className="rounded-button rounded-ends full-width"
                    disabled={this.state.userType === ""}
                    onClick={this.pushPage.bind(this)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lang.common_next[this.state.language],
                      }}
                    />
                  </Button>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
