import React, { useRef, useState, useEffect, useContext } from "react";
import { Page, Button, Carousel, CarouselItem } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faQuoteLeft,
  faBook,
  faHandshakeAngle,
  faList,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";

import IhealContext from "../../Context/IhealContext";
import {
  getSavedByItemHandle,
  deleteSavedItem,
  getSavedResults as getSavedResultsGql,
  createSavedResultItem,
  deleteSavedResultItem,
} from "../../GraphqlService";

import BottomBar from "./BottomBar";
import Home from "./Home";
import Resources from "./Resources";

//import SignUp from './SignUp';

import "../Common/ResourceCard.scss";
import "./Saved.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import PillarIcon from "../Common/PillarIcon";

import ActivityCard from "../Common/ActivityCard";
import ReadingActivity from "../Pillars/ReadingActivity";
import DefaultActivity from "../Pillars/DefaultActivity";
import ResourceCard from "../Common/ResourceCard";

//screen for saved objects
//todo: make tabs for quotes/activities/resources
function Saved(props) {
  const { navigator } = props;

  const iHeal = useContext(IhealContext);

  const [carouselIndex, setCarouselIndex] = useState(1);

  const [savedQuotesLoaded, setSavedQuotesLoaded] = useState(false);
  const [savedQuotes, setSavedQuotes] = useState();

  const [savedArticlesLoaded, setSavedArticlesLoaded] = useState(false);
  const [savedArticles, setSavedArticles] = useState();

  const [savedResourcesLoaded, setSavedResourcesLoaded] = useState(false);
  const [savedResources, setSavedResources] = useState();

  const [savedResultsLoaded, setSavedResultsLoaded] = useState(false);
  const [savedResults, setSavedResults] = useState();

  const carouselRef = useRef(null);

  let thisPillar = "";
  let pillarCollection = "";
  if (localStorage.getItem("pillarCollection")) {
    pillarCollection = JSON.parse(localStorage.getItem("pillarCollection"));
  }

  const getSavedQuotes = async (isMounted) => {
    try {
      getSavedByItemHandle(iHeal.userInfo.userid, "quotes").then((res) => {
        if (isMounted) {
          setSavedQuotes(res);
          setSavedQuotesLoaded(true);
          window.dispatchEvent(new Event("resize"));
        }
      });
    } catch (error) {
      console.error("Saved getSavedQuotes", error.message);
    }
  };
  const getSavedArticles = async (isMounted) => {
    try {
      getSavedByItemHandle(iHeal.userInfo.userid, "topics").then((res) => {
        if (isMounted) {
          setSavedArticles(res);
          setSavedArticlesLoaded(true);
          window.dispatchEvent(new Event("resize"));
        }
      });
    } catch (error) {
      console.error("Saved getSavedArticles", error.message);
    }
  };
  const getSavedResources = async (isMounted) => {
    try {
      getSavedByItemHandle(iHeal.userInfo.userid, "resources").then((res) => {
        if (isMounted) {
          setSavedResources(res);
          setSavedResourcesLoaded(true);
          window.dispatchEvent(new Event("resize"));
        }
      });
    } catch (error) {
      console.error("Saved getSavedResources", error.message);
    }
  };
  const getSavedResults = async (isMounted) => {
    try {
      getSavedResultsGql(iHeal.userInfo.userid).then((res) => {
        if (isMounted) {
          const savedRes = res.map((item) => ({ ...item, saved: true }));
          setSavedResults(savedRes);
          setSavedResultsLoaded(true);
          window.dispatchEvent(new Event("resize"));
        }
      });
    } catch (error) {
      console.error("Saved getSavedResults", error.message);
    }
  };

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;
    getSavedQuotes(isMounted);
    getSavedArticles(isMounted);
    getSavedResources(isMounted);
    getSavedResults(isMounted);

    return () => {
      isMounted = false;
    };
  }, [iHeal]);

  const contentTabs = [
    {
      id: 1,
      slug: "quotes",
      icon: faQuoteLeft,
      title_en: "Quotes",
      title_fr: "Citations",
    },
    {
      id: 2,
      slug: "topics",
      icon: faBook,
      title_en: "Topics",
      title_fr: "Sujets",
    },
    {
      id: 3,
      slug: "resources",
      icon: faHandshakeAngle,
      title_en: "Resources",
      title_fr: "Ressources",
    },
    {
      id: 4,
      slug: "results",
      icon: faList,
      title_en: "Results",
      title_fr: "Résultats",
    },
  ];
  let displayNav = "title_en";
  if (iHeal.userInfo.language === "fr") displayNav = "title_fr";

  // const handleCallback = (childData) => {
  //   let screenName = childData;
  //   console.log(childData);
  //   switch(screenName) {
  //     case "home":
  //       props.navigator.resetPage({component: Home, key: 'home-screen'});
  //       break;
  //     case "saved":
  //       props.navigator.pushPage({component: Saved, key: 'saved-screen'});
  //       break;
  //     default:
  //       break;
  //   }

  // }
  function handleCallback(childData) {
    // console.log( {childData} );

    //if childata is just a string, then it navigates to a couple basic screen areas like home or default activity
    if (typeof childData === "string") {
      let screenName = childData;

      switch (screenName) {
        case "home":
          //todo: fix screen disappearing after multiple back and forths to homescreen
          //https://community.onsen.io/topic/3411/react-navigator-resetpage-causes-blank-screen
          //related to display: none being set on all page screens
          props.navigator.resetPage({
            component: Home,
          });
          break;
        case "saved":
          props.navigator.pushPage({
            component: Saved,
            props: { key: "saved-screen" },
          });
          break;
        case "resources":
          props.navigator.pushPage({
            component: Resources,
            key: "resources-screen",
          });
          break;
        default:
          break;
      }
    } else {
      //if childata is a beefy object, then it's referring to an activity/topic
      // props.navigator.pushPage({component: ReadingActivity, key: 'reading-activity', props: {name: childData.name, icon: childData.icon, type: childData.type, pillarName: props.pillarName, pillarColor: props.pillarColor, pillarIcon: props.pillarIcon}})
      if (childData.type === "defaultQuiz") {
        props.navigator.pushPage({
          component: DefaultActivity,
          key: "default-quiz",
          props: {
            type: childData.type,
            pillarItem: childData.pillarItem,
            activityId: childData.activityInfo.id,
            fromSaved: true,
          },
        });
      } else {
        props.navigator.pushPage({
          component: ReadingActivity,
          key: "reading-activity",
          props: {
            type: childData.type,
            pillarItem: childData.pillarItem,
            activityId: childData.activityInfo.id,
            fromSaved: true,
          },
        });
      }
    }
  }
  function handleRemoveResourceCallback() {
    // console.log( 'handleRemoveResourceCallback' );
    setSavedResourcesLoaded(false);
    getSavedResources(true);
  }

  const updateContent = (newContentId) => {
    // console.log("go to " + newContentId);
    carouselRef.current.setActiveIndex(newContentId - 1);
  };
  const carouselUpdate = (e) => {
    setCarouselIndex(e.activeIndex + 1);
  };

  function updateSavedResult(id, saved) {
    setSavedResults((prev) => {
      let updated = prev.find((item) => item.id === id);
      updated.saved = saved;
      return [...prev];
    });
  }

  function removeSavedQuote(quoteId) {
    const updateSavedQuotes = async () => {
      try {
        deleteSavedItem(iHeal.userInfo.userid, quoteId).then((res) => {
          if (res) {
            if (quoteId === localStorage.getItem("todaysQuoteId")) {
              localStorage.setItem("todaysQuoteSaved", "");
            }
            setSavedQuotesLoaded(false);
            getSavedQuotes(true);
          } else {
            console.log("couldn't delete");
          }
        });
      } catch (error) {
        console.error("Saved getSavedQuotes", error.message);
      }
    };
    updateSavedQuotes();
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  const ResultsCard = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { result } = props;
    const langs = JSON.parse(result.resultsData);
    const id = result.id;
    const saved = result.saved;
    const createdDate = new Date(result.dateCreated);

    const toggleSaved = () => {
      setExpanded((curr) => !curr);
      if (saved) {
        deleteSavedResultItem(iHeal.userInfo.userid, id)
          .then((res) => {
            updateSavedResult(id, false);
          })
          .catch((error) => {
            console.error("ResultsCard deleteSavedResultItem", error.message);
          });
      } else {
        createSavedResultItem(
          iHeal.userInfo.userid,
          result.savedItem[0].id,
          "result",
          result.resultsData,
        )
          .then((res) => {
            updateSavedResult(id, true);
          })
          .catch((error) => {
            console.error("ResultsCard createSavedResultItem", error.message);
          });
      }
    };

    return (
      <div
        className={"result-card" + (expanded ? " expanded" : "")}
        onClick={() => setExpanded((curr) => !curr)}
      >
        <header>
          <h2>{result.savedItem[0].title}</h2>
          <div className="card-right">
            <p>{createdDate.toDateString()}</p>
            <FontAwesomeIcon
              onClick={() => toggleSaved()}
              icon={saved ? faHeart : faHeartOutline}
            />
          </div>
        </header>
        <div className="content-container">
          <div
            className="result-content"
            dangerouslySetInnerHTML={{ __html: langs[iHeal.userInfo.language] }}
          ></div>
        </div>
        <div className="expand-icon">
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        </div>
      </div>
    );
  };

  return (
    <Page
      className="saved-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>

        <div className="flexside flex-90 flex-left">
          <div className="pillar-icon">
            <PillarIcon slug="saved" />
          </div>
          <h1 className="center-text pillar-title">
            {lang.saved_title[iHeal.userInfo.language]}
          </h1>

          <div id="saved-nav">
            <ul>
              {contentTabs.map((item) => (
                <li
                  key={item.id}
                  className={item.id === carouselIndex ? "active" : ""}
                  onClick={() => updateContent(item.id)}
                >
                  <FontAwesomeIcon icon={item.icon} /> {item[displayNav]}
                </li>
              ))}
            </ul>
          </div>

          <div style={{ width: "100%" }}>
            <Carousel
              fullscreen
              ref={carouselRef}
              id="savedCarousel"
              onPostChange={(e) => carouselUpdate(e)}
              // direction="horizontal"
              // itemWidth="100%"
              // overscrollable
              swipeable
              autoScroll
            >
              <CarouselItem className="savedCarousel-item">
                {savedQuotesLoaded ? (
                  <div>
                    {savedQuotes ? (
                      <div>
                        {savedQuotes.map((item) => (
                          <div key={item.id} className="saved-quote">
                            <FontAwesomeIcon
                              icon={faHeart}
                              onClick={() => removeSavedQuote(item.id)}
                            />
                            <p>
                              {item[
                                `description_${iHeal.userInfo.language}`
                              ].replace(/(<([^>]+)>)/gi, "")}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>{lang.saved_noQuotes[iHeal.userInfo.language]}</p>
                    )}
                  </div>
                ) : (
                  // I don't know why but this first slide needs content that wraps around or the slides don't display properly
                  <p>{lang.common_loading[iHeal.userInfo.language]}</p>
                )}
              </CarouselItem>
              <CarouselItem className="savedCarousel-item">
                {savedArticlesLoaded ? (
                  <div style={{ width: "100%" }}>
                    {savedArticles ? (
                      <div
                        className="flexbox flex-90"
                        style={{
                          width: "100%",
                          position: "relative",
                          justifyContent: "space-between",
                        }}
                      >
                        {savedArticles.map((item) => {
                          thisPillar = pillarCollection.filter(function (e) {
                            return e.id === item.pillar[0].id;
                          })[0];
                          return (
                            <ActivityCard
                              key={item.id}
                              title={item[`title_${iHeal.userInfo.language}`]}
                              typeHandle={item.typeHandle}
                              icon={item.articleIcon[0].url}
                              color={thisPillar.pillarColourHex}
                              parentCallback={handleCallback.bind(this)}
                              pillarItem={thisPillar}
                              activityInfo={item}
                              fromSaved={true}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <p style={{ width: "100%" }}>
                        {lang.saved_noTopics[iHeal.userInfo.language]}
                      </p>
                    )}
                  </div>
                ) : (
                  <p style={{ width: "100%" }}>
                    {lang.common_loading[iHeal.userInfo.language]}
                  </p>
                )}
              </CarouselItem>
              <CarouselItem className="savedCarousel-item">
                {savedResourcesLoaded ? (
                  <div style={{ width: "100%" }}>
                    {savedResources && savedResources.length > 0 ? (
                      <div>
                        {savedResources.map((item) => {
                          return (
                            <ResourceCard
                              key={item.id}
                              resourceInfo={item}
                              lang={iHeal.userInfo.language}
                              savedCallback={handleRemoveResourceCallback.bind(
                                this,
                              )}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <p style={{ width: "100%" }}>
                        {lang.saved_noResources[iHeal.userInfo.language]}
                      </p>
                    )}
                  </div>
                ) : (
                  <p style={{ width: "100%" }}>
                    {lang.common_loading[iHeal.userInfo.language]}
                  </p>
                )}
              </CarouselItem>
              <CarouselItem
                className="savedCarousel-item"
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                }}
              >
                {savedResultsLoaded ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    {savedResults.length ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        {savedResults.map((item) => {
                          return <ResultsCard key={item.id} result={item} />;
                        })}
                      </div>
                    ) : (
                      <p style={{ width: "100%" }}>
                        {lang.saved_noResults[iHeal.userInfo.language]}
                      </p>
                    )}
                  </div>
                ) : (
                  <p style={{ width: "100%" }}>
                    {lang.common_loading[iHeal.userInfo.language]}
                  </p>
                )}
              </CarouselItem>
            </Carousel>
          </div>

          <br />
          <br />
          <Button
            className="rounded-button rounded-ends full-width"
            onClick={() => navigator.popPage()}
          >
            {lang.common_goBack[iHeal.userInfo.language]}
          </Button>
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default Saved;
