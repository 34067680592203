import React, { useState, useEffect, useContext } from "react";
import {
  Page,
  Icon,
  Splitter,
  SplitterContent,
  SplitterSide,
  List,
  ListItem,
} from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faUserXmark,
  faComment,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
//import { PrismicRichText, useFirstPrismicDocument } from '@prismicio/react'

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import {
  getRandomQuote,
  createSavedItem,
  itemAlreadySaved,
  deleteSavedItem,
} from "../../GraphqlService.js";

import "./Home.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import Pillar from "../Pillars/Pillar";
import Saved from "./Saved";
import Resources from "./Resources";
import BottomBar from "./BottomBar";
import ResourcesList from "../Common/ResourcesList";
import Pillars from "./Pillars";
import OnboardingTour from "./OnboardingTour";

//settings screens
import Contact from "../Settings/Contact";
import Delete from "../Settings/Delete";
import Feedback from "../Settings/Feedback";
import Terms from "../Settings/Terms";
import ChangePassword from "../Settings/ChangePassword";
import SaveHeart from "../Common/SaveHeart.jsx";

//content and layout that appears on the home page
function HomePage(props) {
  const { parentCallback } = props;
  // console.log( props );

  const eventTracker = GaEventTracker("Home");
  const iHeal = useContext(IhealContext);

  let todaysQuote = "";
  if (localStorage.getItem("todaysQuote")) {
    todaysQuote = localStorage.getItem("todaysQuote");
  }
  let todaysQuoteId = "";
  if (localStorage.getItem("todaysQuoteId")) {
    todaysQuoteId = localStorage.getItem("todaysQuoteId");
  }

  const [randomQuote, setRandomQuote] = useState(
    todaysQuote.replace(/(<([^>]+)>)/gi, ""),
  );
  const [quoteLoaded, setQuoteLoaded] = useState(false);
  const [quoteId, setQuoteId] = useState(todaysQuoteId);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getQuote = async () => {
      try {
        getRandomQuote().then((res) => {
          if (isMounted) {
            setRandomQuote(
              res[`description_${iHeal.userInfo.language}`].replace(
                /(<([^>]+)>)/gi,
                "",
              ),
            );
            localStorage.setItem(
              "todaysQuote",
              res[`description_${iHeal.userInfo.language}`],
            );
            localStorage.setItem("todaysQuoteId", res.id);
            setQuoteLoaded(true);
            setQuoteId(res.id);

            // this quote saved?
            // checkSavedItem(iHeal.userInfo.userid, res.id);
          }
        });
      } catch (error) {
        console.error("Home getQuote", error.message);
      }
    };
    if (randomQuote === "") getQuote();
    else setQuoteLoaded(true);

    return () => {
      isMounted = false;
    };
  }, [iHeal, randomQuote]);

  async function toggleSavedQuote(isSaved) {
    if (isSaved) {
      // delete saved quote
      await deleteSavedItem(
        iHeal.userInfo.userid,
        localStorage.getItem("todaysQuoteId"),
      );
    } else {
      // create saved item
      await createSavedItem(
        iHeal.userInfo.userid,
        localStorage.getItem("todaysQuoteId"),
        "quotes",
      );
    }
  }

  const renderToolbar = () => <Header type="home" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={parentCallback} />
  );

  return (
    <Page
      className="home-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <OnboardingTour />

      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {/* today's quote */}
          <div className="flexbox nowrap jc-spacebetween">
            <div className="todays-heading">
              <div>{lang.home_todaysquote[iHeal.userInfo.language]}</div>
            </div>
            <div className="the-quote">
              {quoteLoaded ? (
                <div style={{ fontStyle: "italic" }}>“{randomQuote}”</div>
              ) : (
                <div>{lang.common_loading[iHeal.userInfo.language]}</div>
              )}
            </div>
            <SaveHeart
              checkSaved={async () => {
                if (quoteId) {
                  return await itemAlreadySaved(iHeal.userInfo.userid, quoteId);
                } else {
                  return undefined;
                }
              }}
              toggleSaved={toggleSavedQuote}
            />
          </div>

          <p className="home-explore">
            {lang.home_explore[iHeal.userInfo.language]}{" "}
          </p>

          <Pillars
            parentCallback={parentCallback}
            eventTracker={eventTracker}
          />

          <ResourcesList />
        </div>
        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export { HomePage };

//component that handles home navigation system, comprises of navigation sidemenu settings
//default page is the homepage above
class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: localStorage.getItem("language"),
    };
  }

  //function which handles that callback from the bottom tabbar + pillar selection
  //repeated in multiple places can probably be refactored out
  handleCallback(screen, pillarItem) {
    // console.log( screen, pid );
    switch (screen) {
      case "home":
        this.props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        this.props.navigator.pushPage({
          component: Saved,
          props: { key: "saved-screen" },
        });
        break;
      case "resources":
        this.props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        this.props.navigator.pushPage({
          component: Pillar,
          key: "pillar-screen",
          props: { pillarItem: pillarItem },
        });
    }
  }

  // handleExit() {
  //     // window.close() cannot close tabs that were not opened via script - best alternative we have
  //     window.location.replace("https://www.theweathernetwork.com/ca");
  // }

  render() {
    return (
      <Page>
        <Splitter className="sidebar">
          <SplitterSide id="menu" side="left" width="80%" collapse swipeable>
            <Page>
              <List className="list-container">
                <ListItem
                  className="close-menu mouseclick"
                  tappable
                  onClick={() => document.getElementById("menu").close()}
                >
                  <Icon icon="fa-close" />
                </ListItem>
                {/* <ListItem tappable onClick={() => this.handleExit()}>
                        <Icon icon="fa-circle" style={{paddingRight: "10px", color: "#fac4bb"}}/>
                        <span className="sidebar-item">Emergency Exit</span> 
                      </ListItem> */}
                <ListItem
                  tappable
                  className="mouseclick"
                  onClick={() =>
                    this.props.navigator.pushPage({
                      component: ChangePassword,
                      key: "changepassword-screen",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faKey} />
                  <span className="sidebar-item">
                    {lang.sidebar_password[this.state.language]}
                  </span>
                </ListItem>
                <ListItem
                  tappable
                  className="mouseclick"
                  onClick={() =>
                    this.props.navigator.pushPage({
                      component: Delete,
                      key: "delete-screen",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faUserXmark} />
                  <span className="sidebar-item">
                    {lang.sidebar_delete[this.state.language]}
                  </span>
                </ListItem>
                {/* <ListItem tappable className="mouseclick" onClick={() => this.props.navigator.pushPage({component: Contact, key: 'contact-screen'})}>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span className="sidebar-item">{ lang.sidebar_contact[ this.state.language ] }</span>
                      </ListItem> */}
                <ListItem
                  tappable
                  className="mouseclick"
                  onClick={() =>
                    this.props.navigator.pushPage({
                      component: Feedback,
                      key: "feedback",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faComment} />
                  <span className="sidebar-item">
                    {lang.sidebar_feedback[this.state.language]}
                  </span>
                </ListItem>
                <ListItem
                  tappable
                  className="mouseclick"
                  onClick={() =>
                    this.props.navigator.pushPage({
                      component: Terms,
                      key: "terms",
                    })
                  }
                >
                  <FontAwesomeIcon icon={faBookOpen} />
                  <span className="sidebar-item">
                    {lang.sidebar_terms[this.state.language]}
                  </span>
                </ListItem>
                {/* <ListItem tappable onClick={() => this.props.navigator.pushPage({component: Share, key: 'share-screen'})}>
                        <Icon icon="fa-circle" style={{paddingRight: "10px", color: "#bddad5"}}/>
                        <span className="sidebar-item">{ lang.sidebar_share[ this.state.language ] }</span>
                      </ListItem> */}
              </List>
            </Page>
          </SplitterSide>
          <SplitterContent>
            <HomePage parentCallback={this.handleCallback.bind(this)} />
          </SplitterContent>
        </Splitter>
      </Page>
    );
  }
}
export default Home;
