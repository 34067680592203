import React, { useContext } from "react";
import { Button } from "react-onsenui";

import IhealContext from "../../Context/IhealContext";
import { itemAlreadySaved } from "../../GraphqlService";

import "./ActivityCard.scss";

import lang from "../../Assets/data/translations.json";
import SaveHeart from "./SaveHeart";

//activity icon component, also known as topics, can have activity label on it to signify a more elaborate activity is associated with it
//each activity has different types it can be -> leads to different screens
function ActivityCard(props) {
  // console.log( props );
  const {
    title,
    icon,
    color,
    parentCallback,
    typeHandle,
    activityInfo,
    pillarItem,
    fromSaved,
  } = props;
  let activityType, isActivity;
  switch (typeHandle) {
    case "articles":
      activityType = "basic-reading";
      isActivity = false;
      break;
    case "quiz":
      activityType = "default-quiz";
      isActivity = true;
      break;
    case "multiQuiz":
      activityType = "multi-item-list";
      isActivity = true;
      break;
    case "wordQuilt":
      activityType = "word-quilt";
      isActivity = true;
      break;
    case "wheelActivity":
      activityType = "wheel-slideshow";
      isActivity = true;
      break;
    default:
      activityType = "basic-reading";
      isActivity = false;
      break;
  }
  const iHeal = useContext(IhealContext);

  const checkActivityType = () => {
    console.log("checkActivityType", activityType, activityInfo, pillarItem);
    switch (activityType) {
      case "wheel-slideshow":
        parentCallback({
          type: "wheelActivity",
          pillarItem: pillarItem,
          activityInfo: activityInfo,
          fromSaved,
        });
        break;
      case "multi-item-list":
        parentCallback({
          type: "multiItemListActivity",
          pillarItem: pillarItem,
          activityInfo: activityInfo,
          fromSaved,
        });
        break;
      case "word-quilt":
        parentCallback({
          type: "wordQuilt",
          pillarItem: pillarItem,
          activityInfo: activityInfo,
          fromSaved,
        });
        break;
      case "basic-reading":
        parentCallback({
          type: "basicReading",
          pillarItem: pillarItem,
          activityInfo: activityInfo,
          fromSaved,
        });
        break;
      case "default-quiz":
        parentCallback({
          type: "defaultQuiz",
          pillarItem: pillarItem,
          activityInfo: activityInfo,
          fromSaved,
        });
        break;
      default:
        break;
    }
  };

  return (
    // <div id="activity-icon" onClick={() => checkActivityType()}>
    //     <div className="circle-holder" style={{ backgroundColor: `${color}` }}>
    //         <img src={icon} alt="Activity" className='activity-icon'/>
    //         {isActivity &&
    //             <div className="rectangle">
    //                 <div className="activity-note">Activity</div>
    //             </div>
    //         }
    //     </div>
    //     <div className='activity-title'>{title}</div>
    // </div>
    <Button
      onClick={() => checkActivityType()}
      className="activity-icon rounded-button full-width"
    >
      <div className="flexbox" style={{ alignItems: "stretch" }}>
        <div
          className="flexside ai-center jc-center flex-10"
          style={{
            background: `center / contain no-repeat ${color} url('${icon}')`,
          }}
        ></div>
        <div className="activity-icon-info flex-90 flexbox">
          <div className="heading flexside jc-center flex-70 flex-left">
            <div>{title}</div>
          </div>
          <div className="activity-type flexside flex-20">
            {isActivity
              ? lang.pillar_Activity[iHeal.userInfo.language]
              : lang.pillar_Info[iHeal.userInfo.language]}
          </div>
          <div className="activity-saved flexside flex-10">
            <SaveHeart
              checkSaved={() =>
                itemAlreadySaved(iHeal.userInfo.userid, activityInfo.id)
              }
            />
          </div>
        </div>
      </div>
    </Button>
  );
}
export default ActivityCard;
