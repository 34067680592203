import React, { useState, useEffect, useContext } from "react";
import { Page, Button, Checkbox } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import { getDefaultBySlug, closeAccount } from "../../GraphqlService.js";

import BottomBar from "../Homescreen/BottomBar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";

import "./Settings.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

//basic delete page
function Delete(props) {
  const { navigator } = props;

  const eventTracker = GaEventTracker("Sidebar");
  const iHeal = useContext(IhealContext);

  const [deleteAccepted, setDeleteAccepted] = useState(false);
  const [defaultContent, setDefaultContent] = useState("");
  const [contentLoaded, setContentLoaded] = useState(false);
  const [gaTrackingDone, setGaTrackingDone] = useState(false);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getContent = async () => {
      try {
        getDefaultBySlug("delete-account").then((res) => {
          if (isMounted) {
            setDefaultContent(res);
            setContentLoaded(true);
          }
        });
      } catch (error) {
        console.error("About getDefaultBySlug", error.message);
      }
    };
    getContent();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!gaTrackingDone) {
    eventTracker("Delete Account", "", iHeal.userInfo.userid);
    setGaTrackingDone(true);
  }

  const handleCallback = (childData) => {
    let screenName = childData;
    console.log(childData);
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  };

  const deleteAccount = () => {
    eventTracker("Delete Account", "success", iHeal.userInfo.userid);
    // console.log("account deleted");
    closeAccount(iHeal.userInfo.userid);
    window.location.reload();
  };

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className="setting-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {contentLoaded ? (
            <>
              <h1 className="center-text pillar-title">
                {defaultContent["title_" + iHeal.userInfo.language]}
              </h1>

              <div
                dangerouslySetInnerHTML={{
                  __html: defaultContent["body_" + iHeal.userInfo.language],
                }}
              />
            </>
          ) : (
            <p>{lang.common_loading[iHeal.userInfo.language]}</p>
          )}
          <div className="container" style={{ margin: "0 auto" }}>
            <Checkbox
              onChange={() => setDeleteAccepted(!deleteAccepted)}
              modifier="material"
              input-id="yes-im-sure"
              style={{ marginRight: "10px" }}
            />
            <label htmlFor="yes-im-sure">
              {lang.common_yesSure[iHeal.userInfo.language]}
            </label>
          </div>
          <br />
          <Button
            className="rounded-button rounded-ends full-width"
            style={{ color: "rgb(19, 162, 220)" }}
            disabled={!deleteAccepted}
            onClick={() => deleteAccount()}
          >
            {lang.common_delete[iHeal.userInfo.language]}
          </Button>
          <br />
          <br />
          <Button
            className="rounded-button rounded-ends full-width"
            style={{ color: "rgb(19, 162, 220)", marginTop: "1rem" }}
            onClick={() => navigator.popPage()}
          >
            {lang.common_goBack[iHeal.userInfo.language]}
          </Button>
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default Delete;
