import React from "react";
import { Page, Button, Select } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";

import "./Location.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import Intro from "./Intro";

export default class Location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      location: "",
      language: localStorage.getItem("language"),
      windowHeight: window.innerHeight,
    };
  }

  //resize code helps make the scroll box have a bit more of a responsive design to different sized screens
  handleResize() {
    this.setState({
      windowHeight: window.innerHeight,
    });
    console.log("windwo resize: " + this.state.windowHeight);
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.handleResize());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize());
  }

  pushPage() {
    const eventTracker = GaEventTracker("Location");
    eventTracker(this.state.location);
    this.props.navigator.pushPage({ component: Intro, key: "intro-screen" });
  }

  handleClick(data) {
    // console.log(data);

    this.setState({
      location: data.value,
    });
    var location = data.value;

    if (!this.state.onboardedUser) {
      localStorage.setItem("location", location);
    }
  }

  render() {
    return (
      <Page className="location-screen location">
        <div className="page-container-100 scroll-overflow">
          <div className="flex-scroll">
            <Header type="accountCreation" lang={this.state.language} />

            <div className="page-container-30">
              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center center jc-center">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: lang.location_title[this.state.language],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: lang.location_intro[this.state.language],
                    }}
                  />
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-50">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center jc-center">
                  <Select
                    modifier="material"
                    value={this.state.value}
                    onChange={(event) =>
                      this.handleClick({ value: event.target.value })
                    }
                  >
                    <option value="" selected disabled hidden>
                      {lang.location_selectArea[this.state.language]}
                    </option>
                    <option value="AB">
                      {lang.location_AB[this.state.language]}
                    </option>
                    <option value="BC">
                      {lang.location_BC[this.state.language]}
                    </option>
                    <option value="MB">
                      {lang.location_MB[this.state.language]}
                    </option>
                    <option value="NB">
                      {lang.location_NB[this.state.language]}
                    </option>
                    <option value="NL">
                      {lang.location_NL[this.state.language]}
                    </option>
                    <option value="NT">
                      {lang.location_NT[this.state.language]}
                    </option>
                    <option value="NS">
                      {lang.location_NS[this.state.language]}
                    </option>
                    <option value="NU">
                      {lang.location_NU[this.state.language]}
                    </option>
                    <option value="ON">
                      {lang.location_ON[this.state.language]}
                    </option>
                    <option value="PE">
                      {lang.location_PE[this.state.language]}
                    </option>
                    <option value="QC">
                      {lang.location_QC[this.state.language]}
                    </option>
                    <option value="SK">
                      {lang.location_SK[this.state.language]}
                    </option>
                    <option value="YT">
                      {lang.location_YT[this.state.language]}
                    </option>
                  </Select>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-10">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center jc-center">
                  <Button
                    className="rounded-button rounded-ends full-width"
                    disabled={this.state.location === ""}
                    onClick={this.pushPage.bind(this)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lang.common_next[this.state.language],
                      }}
                    />
                  </Button>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
