import React from "react";
import { Page, Carousel, CarouselItem, Fab, Icon } from "react-onsenui";

import "./Intro.scss";
import onboarding_img from "../../Assets/images/group-conversation.png";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import SignUp from "./SignUp";

//intro screen, has slideshow of 3 screens to infom user about the app
export default class Intro extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselIndex: 0,
      language: localStorage.getItem("language"),
    };
  }

  pushPage() {
    this.props.navigator.pushPage({
      component: SignUp,
      props: { key: "signup-screen" },
    });
  }

  next() {
    var carousel = document.getElementById("carousel");
    if (this.state.carouselIndex === 2) {
      this.pushPage();
      return;
    }
    carousel.next();
    return;
  }

  carouselUpdate(e) {
    this.setState({
      carouselIndex: e.activeIndex,
    });
  }

  render() {
    return (
      <Page className="intro-screen intro">
        <div className="page-container-100 scroll-overflow">
          <div className="flex-scroll">
            <Header type="intro" lang={this.state.language} />

            <div className="page-container-90">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <Carousel
                fullscreen
                id="carousel"
                onPostChange={(e) => this.carouselUpdate(e)}
              >
                <CarouselItem className="carousel-item">
                  <div className="flexbox">
                    <div className="flex-center body-text jc-center ai-center">
                      <p style={{ textAlign: "center" }}>
                        <img alt="" src={onboarding_img} />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang.intro_slide1[this.state.language],
                        }}
                      />
                    </div>
                  </div>
                </CarouselItem>
                <CarouselItem className="carousel-item">
                  <div className="flexbox">
                    <div className="body-text flex-center jc-center ai-center">
                      <p style={{ textAlign: "center" }}>
                        <img alt="" src={onboarding_img} />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang.intro_slide2[this.state.language],
                        }}
                      />
                    </div>
                  </div>
                </CarouselItem>
                <CarouselItem className="carousel-item">
                  <div className="flexbox">
                    <div className="body-text flex-center jc-center ai-center">
                      <p style={{ textAlign: "center" }}>
                        <img alt="" src={onboarding_img} />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lang.intro_slide3[this.state.language],
                        }}
                      />
                    </div>
                  </div>
                </CarouselItem>
              </Carousel>
            </div>

            <div className="page-container-10">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20">
                  <div style={{ flexGrow: 1 }}>
                    {/* <Icon icon={`${this.state.carouselIndex === 0 ? "circle" : "circle-notch"}`} size={10} fixedWidth={false} style={{verticalAlign: 'middle', padding: '4px'}}/> */}
                    <Icon
                      className={`${
                        this.state.carouselIndex === 0 ? "" : "circle-outline"
                      }`}
                      icon="circle"
                      size={10}
                      fixedWidth={false}
                      style={{
                        verticalAlign: "middle",
                        padding: "4px",
                      }}
                    />
                    <Icon
                      className={`${
                        this.state.carouselIndex === 1 ? "" : "circle-outline"
                      }`}
                      icon="circle"
                      size={10}
                      fixedWidth={false}
                      style={{
                        verticalAlign: "middle",
                        padding: "4px",
                      }}
                    />
                    <Icon
                      className={`${
                        this.state.carouselIndex === 2 ? "" : "circle-outline"
                      }`}
                      icon="circle"
                      size={10}
                      fixedWidth={false}
                      style={{
                        verticalAlign: "middle",
                        padding: "4px",
                      }}
                    />
                  </div>
                </div>

                <div className="flexside flex-60"></div>

                <div className="flexside flex-20 flex-center jc-center">
                  <Fab onClick={() => this.next()} ripple>
                    <Icon
                      icon="fa-chevron-right"
                      size={26}
                      fixedWidth={false}
                      style={{ verticalAlign: "middle" }}
                    />
                  </Fab>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
