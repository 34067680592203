import axios from "axios";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";

const headers = {
  "content-type": "application/json",
};

export async function graphqlTest() {
  const graphqlQuery = {
    operationName: "pingTest",
    query: `query pingTest {
            ping
          }`,
    variables: {},
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data;
}

/*
    used for sign up and login
    param: user entered string
    returns: encrypted string
*/
export function hashString(str) {
  const hashDigest = sha256(process.env.REACT_APP_CRYPTO_NONCE + str);
  const hmacDigest = Base64.stringify(
    hmacSHA512(
      process.env.REACT_APP_CRYPTO_PATH + hashDigest,
      process.env.REACT_APP_CRYPTO_PRIVATEKEY,
    ),
  );
  return hmacDigest;
}

/*
    check if the username already exsits in the db
    param: username to check (should already be encrypted)
    returns: 
        true if username already exsits in db
        false if username can be used
*/
export async function usernameExists(username) {
  const graphqlQuery = {
    operationName: "usernameExists",
    query: `query usernameExists( $appUsername: [QueryArgument] ) {
            entryCount(section: "appUsers", appUsername: $appUsername)
          }`,
    variables: { appUsername: username },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data.data.entryCount );
  if (response.data.data.entryCount > 0) {
    return true;
  }
  return false;
}

/*
    create new user in db
    param: object of user data including username (already encrypted), password (already encrypted), language, province
    returns: new user's entry id
*/
export async function createUser(userData) {
  const graphqlQuery = {
    operationName: "saveAppUser",
    query: `mutation saveAppUser( $username: String, $password: String, $lang: String, $prov: String, $userType: String) {
            save_appUsers_default_Entry(
                authorId: "${process.env.REACT_APP_APPUSER_AUTHORID}", 
                appUsername: $username, 
                password: $password,
                lang: $lang,
                province: $prov,
                useage: $userType
            ) {
                id
                title
            }
          }`,
    variables: {
      username: userData.username,
      password: userData.password,
      lang: userData.language,
      prov: userData.province,
      userType: userData.userType,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.save_appUsers_default_Entry.id;
}

/*
    checks user login info
    params: object of entered username (already encrypted), password (already encrypted)
    returns: 
        false if user doesn't exist
        object of user info (id, language, province)
*/
export async function appuserLogin(userData) {
  const graphqlQuery = {
    operationName: "appuserLogin",
    query: `query appuserLogin($appUsername: [QueryArgument], $password: [QueryArgument]) {
            entries(section: "appUsers", appUsername: $appUsername, password: $password) {
                id
                title
                ... on appUsers_default_Entry {
                    lang
                    province
                }
            }
        }`,
    variables: {
      appUsername: userData.username,
      password: userData.password,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  if (response.data.data.entries.length === 0) {
    return false;
  }

  const userInfo = {
    userid: response.data.data.entries[0].id,
    language: response.data.data.entries[0].lang,
    province: response.data.data.entries[0].province,
    showTour: false,
  };
  return userInfo;
}

/*
    change user password
    params: current user id, current password (already encrypted), new password
    returns: 
        true on success
        false if can't change password
*/
export async function changePassword(userid, currentPassword, newPassword) {
  const graphqlQuery = {
    operationName: "checkCurrentPassword",
    query: `query checkCurrentPassword($userid: [QueryArgument], $currentPassword: [QueryArgument]) {
            entries(section: "appUsers", id: $userid, password: $currentPassword) {
                id
                title
            }
        }`,
    variables: {
      userid: parseInt(userid),
      currentPassword: currentPassword,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  console.log("checkCurrentPassword", response.data.data.entries);
  if (response.data.data.entries.length === 0) {
    return false;
  }

  // update user password
  const graphqlQuery2 = {
    operationName: "updatePassword",
    query: `mutation updatePassword( $userid: ID = "", $newPassword: String = "" ) {
            save_appUsers_default_Entry(
                id: $userid, 
                password: $newPassword
            ) {
                id
                title
            }
          }`,
    variables: {
      userid: parseInt(userid),
      newPassword: newPassword,
    },
  };
  const response2 = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery2,
  });
  console.log(response2);
  return true;
}

/*
    get url to link exit button to
    param: none
    returns: url as string
*/
export async function getExitURL() {
  const graphqlQuery = {
    operationName: "getExitURL",
    query: `query getExitURL {
            entries(section: "exitUrl") {
                exitUrl
            }
        }`,
    variables: {},
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  if (
    "data" in response &&
    "data" in response.data &&
    "entries" in response.data.data &&
    response.data.data.entries.length >= 1 &&
    "exitUrl" in response.data.data.entries[0]
  ) {
    return response.data.data.entries[0].exitUrl;
  } else {
    throw Error("Problem querying for exit URL");
  }
}

/*
    get text related to exit url
	param: key ("onboardingTourText" or "accountCreationCheck"), lang ("en" or "fr")
    returns: string
*/
export async function getExitText(key, lang) {
  const full_key = key + "_" + lang;
  const graphqlQuery = {
    operationName: "getExitText",
    query: `query getExitText {
            entries(section: "exitUrl") {
				${full_key}
            }
        }`,
    variables: {},
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  if (
    "data" in response &&
    "data" in response.data &&
    "entries" in response.data.data &&
    response.data.data.entries.length >= 1 &&
    full_key in response.data.data.entries[0]
  ) {
    return response.data.data.entries[0][full_key];
  } else {
    throw Error("Problem querying for exit text");
  }
}

/*
    get a random quote
    param: none
    returns: object of quote with id, English and French
*/
export async function getRandomQuote() {
  const graphqlQuery = {
    operationName: "getRandomQuote",
    query: `query getRandomQuote {
            entries(section: "quotes", orderBy:"RAND()", limit: 1) {
                id
                ... on quotes_default_Entry {
                    description_en
                    description_fr
                }
            }
        }`,
    variables: {},
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries[0];
}

/*
    get resources by search query
    param: filterBy (province or article), filterValue (user's province or article to be relatedTo), orderBy (title_en or title_fr), search term
    return: object of resources with En/Fr title, En/Fr desc, other info

    home page: show pinned in pinnedOrder
    home page search: alpha display search match (filter user province)
    Topic/Article page: only resources tagged to that topic (alpha display)
*/
// export async function getResources( filterBy, filterValue, orderBy, search = "" ) {
export async function getResources(
  prov,
  alphaOrderBy,
  search = "",
  articleId = "",
) {
  let graphqlQuery = {};
  let returnResources = [];

  if (articleId === "") {
    // home page
    if (search === "") {
      // get pinned by province
      graphqlQuery = {
        operationName: "getPinnedResources",
        query: `query getPinnedResources( $prov: [QueryArgument] ) {
                    entries (section: "resources", pinned: true, resourceProvince: $prov, orderBy: "pinnedOrder") {
                        id,
                        title_en: title
                        ... on resources_default_Entry {
                            title_fr,
                            description_en,
                            description_fr,
                            phone,
                            webURL,
                            tags,
                            logo {
                                url
                            }
                        }
                    }
                }`,
        variables: {
          prov: prov,
        },
      };
      const response = await axios({
        url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        method: "post",
        headers: headers,
        data: graphqlQuery,
      });
      // console.log( response.data.data.entries );
      returnResources = response.data.data.entries;
    } else {
      // get NON PINNED resources that match search
      graphqlQuery = {
        operationName: "getResources",
        query: `query getResources( $prov: [QueryArgument], $searchTerm: String, $alphaOrderBy: String ) {
                    entries (section: "resources", pinned: false, resourceProvince: $prov, orderBy: $alphaOrderBy, search: $searchTerm) {
                        id,
                        title_en: title
                        ... on resources_default_Entry {
                            title_fr,
                            description_en,
                            description_fr,
                            phone,
                            webURL,
                            tags,
                            logo {
                                url
                            }
                        }
                    }
                }`,
        variables: {
          prov: prov,
          searchTerm: search,
          alphaOrderBy: alphaOrderBy,
        },
      };
      const response2 = await axios({
        url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        method: "post",
        headers: headers,
        data: graphqlQuery,
      });
      // console.log( response2.data.data.entries );
      // returnResources.push( ...response2.data.data.entries );
      returnResources = response2.data.data.entries;
    }
  } else {
    // Topic/Article page: only resources tagged to that topic (alpha display)
    graphqlQuery = {
      operationName: "getResources",
      query: `query getResources( $relatedArticles: [QueryArgument], $prov: [QueryArgument], $searchTerm: String, $alphaOrderBy: String ) {
                entries (section: "resources", relatedTo: $relatedArticles, resourceProvince: $prov, orderBy: $alphaOrderBy, search: $searchTerm) {
                    id,
                    title_en: title
                    ... on resources_default_Entry {
                        title_fr,
                        description_en,
                        description_fr,
                        phone,
                        webURL,
                        tags,
                        logo {
                            url
                        }
                    }
                }
            }`,
      variables: {
        prov: prov,
        searchTerm: search,
        alphaOrderBy: alphaOrderBy,
        relatedArticles: articleId,
      },
    };
    const response = await axios({
      url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      method: "post",
      headers: headers,
      data: graphqlQuery,
    });
    // console.log( response.data.data.entries );
    returnResources = response.data.data.entries;
  }

  // console.log( returnResources );

  return returnResources;
}

/*
    get ALL pillars 
    param: none
    return: object of ALL pillars with En/Fr title, En/Fr desc, other info
*/
export async function getPillars() {
  const graphqlQuery = {
    operationName: "getPillars",
    query: `query getPillars {
            entries (section: "pillars") {
                id,
                slug,
                title_en: title
                ... on pillars_default_Entry {
                    title_fr,
                    excerpt_en,
                    excerpt_fr,
                    description_en,
                    description_fr,
                    pillarColourHex,
                    pillarColourClass,
                    icon {
                        url
                    },
                    topicsSubheading_en,
                    topicsSubheading_fr,
                    activityTitle_en,
                    activityTitle_fr,
                    activityDuration_en,
                    activityDuration_fr,
                    activityDescription_en,
                    activityDescription_fr,
                    activityData
                }
            }
        }`,
    variables: {},
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries;
}

/*
    get ONE pillar 
    param: pillar id
    return: object of SINGLE pillars with En/Fr title, En/Fr desc, other info
*/
export async function getPillar(pid) {
  const graphqlQuery = {
    operationName: "getPillar",
    query: `query getPillar( $pid: [QueryArgument] ) {
            entries (section: "pillars", id: $pid, limit: 1) {
                id,
                title_en: title
                ... on pillars_default_Entry {
                    title_fr,
                    description_en,
                    description_fr,
                    pillarColourHex,
                    pillarColourClass,
                    icon {
                        url
                    }
                }
            }
        }`,
    variables: {
      pid: pid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries[0];
}

/*
    get all pillar topics: articles, wheel activity, quizs, word quilt
    param: pillar id
    return: object of topics for this pillar with entry type (!!!), En/Fr title, En/Fr body, other info
*/
export async function getPillarTopics(pid, tid = "*") {
  const graphqlQuery = {
    operationName: "getPillarTopics",
    query: `query getPillarTopics( $pillarID: [QueryArgument], $topicID: [QueryArgument] ) {
            entries(section: "topics", relatedTo: $pillarID, id: $topicID) {
              id
              typeHandle
              title_en: title
              ... on topics_articles_Entry {
                title_fr
                articleIcon {
                  url
                }
                articleBody_en {
                  ... on articleBody_en_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_en_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
                articleBody_fr {
                  ... on articleBody_fr_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_fr_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
              }
              ... on topics_wheelActivity_Entry {
                title_fr
                articleIcon {
                  url
                }
                activityDescription_en
                activityDescription_fr
              }
              ... on topics_quiz_Entry {
                title_fr
                articleIcon {
                  url
                }
                activityDescription_en
                activityDescription_fr
                activityData
              }
              ... on topics_multiQuiz_Entry {
                title_fr
                articleIcon {
                  url
                }
                activityDescription_en
                activityDescription_fr
                activityData
              }
              ... on topics_wordQuilt_Entry {
                title_fr
                articleIcon {
                  url
                }
                activityDescription_en
                activityDescription_fr
                activityData
              }
            }
          }
          `,
    variables: {
      pillarID: pid,
      topicID: tid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries;
}

/*
    get pillar Articles
    param: pillar id, orderBy (title_en or title_fr)
    return: object of articles for this pillar with En/Fr title, En/Fr body, other info
*/
export async function getPillarArticles(pid) {
  const graphqlQuery = {
    operationName: "getPillarArticles",
    query: `query getPillarArticles( $pillarID: [QueryArgument] ) {
            entries(section: "topics", type: "articles", relatedTo: $pillarID) {
              id
              title_en: title
              ... on topics_articles_Entry {
                title_fr
                articleIcon {
                  url
                }
                articleBody_en {
                  ... on articleBody_en_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_en_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
                articleBody_fr {
                  ... on articleBody_fr_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_fr_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
              }
            }
          }
          `,
    variables: {
      pillarID: pid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries;
}

/*
    get pillar Wheel Activities
    param: pillar id, orderBy (title_en or title_fr)
    return: object of Wheel Activities for this pillar with En/Fr title, En/Fr body, other info
*/
export async function getPillarWheelActivities(pid) {
  const graphqlQuery = {
    operationName: "getPillarWheelActivities",
    query: `query getPillarWheelActivities( $pillarID: [QueryArgument] ) {
            entries(section: "topics", type: "wheelActivity", relatedTo: $pillarID) {
              id
              title_en: title
              ... on topics_wheelActivity_Entry {
                title_fr
                activityDescription_en
                activityDescription_fr
                articleIcon {
                  url
                }
              }
            }
          }
          `,
    variables: {
      pillarID: pid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries;
}

/*
    get default entry by slug ie About Us
    param: slug name
    return: object of default entry with En/Fr title, En/Fr body
*/
export async function getDefaultBySlug(slug) {
  const graphqlQuery = {
    operationName: "getDefaultBySlug",
    query: `query getDefaultBySlug( $slug: [String] ) {
            entries(section: "default", slug: $slug, limit: 1) {
                id,
                title_en: title
                ... on default_default_Entry {
                    title_fr,
                    body_en,
                    body_fr
                }
            }
        }`,
    variables: {
      slug: slug,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data.data.entries[0];
}

/*
    create saved item
    param: user id, item to be saved
    return: nothing, let's assume it worked
*/
export async function createSavedItem(userid, itemid, handle) {
  const graphqlQuery = {
    operationName: "createSavedItem",
    query: `mutation createSavedItem( $userid: [Int], $itemid: [Int], $handle: String ) {
            save_saved_default_Entry(
                    authorId: "${process.env.REACT_APP_APPUSER_AUTHORID}", 
                    appUser: $userid, 
                    savedItem: $itemid,
                    savedItemHandle: $handle
                ) {
                id
                title
            }
        }`,
    variables: {
      userid: parseInt(userid),
      itemid: parseInt(itemid),
      handle: handle,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data );
  return response;
}

/*
    create saved item
    param: user id, item to be saved
    return: nothing, let's assume it worked
*/
export async function createSavedResultItem(userid, itemid, handle, result) {
  const graphqlQuery = {
    operationName: "createSavedResultItem",
    query: `mutation createSavedResultItem( $userid: [Int], $itemid: [Int], $handle: String, $result: String ) {
            save_savedResults_default_Entry(
                    authorId: "${process.env.REACT_APP_APPUSER_AUTHORID}", 
                    appUser: $userid, 
                    savedItem: $itemid,
                    savedItemHandle: $handle
                    resultsData: $result
                ) {
                id
                title
            }
        }`,
    variables: {
      userid: parseInt(userid),
      itemid: parseInt(itemid),
      handle: handle,
      result: result,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data );
  return response;
}

/*
    check saved item
    param: user id, item to check
    return: true if already saved, false if not
*/
export async function itemAlreadySaved(userid, itemid) {
  const graphqlQuery = {
    operationName: "itemAlreadySaved",
    query: `query itemAlreadySaved( $userid: [QueryArgument], $itemid: [QueryArgument] ) {
            entryCount(section: "saved", appUser: $userid, savedItem: $itemid)
        }`,
    variables: {
      userid: parseInt(userid),
      itemid: parseInt(itemid),
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  if (response.data.data.entryCount > 0) return true;
  else return false;
}

/*
    delete saved item
    param: user id, item to delete save
    return: nothing, let's assume it worked
*/
export async function deleteSavedResultItem(userid, itemid) {
  const graphqlQuery = {
    operationName: "getSavedResultId",
    query: `query getSavedResultId( $userid: [QueryArgument], $itemid: [QueryArgument] ) {
            entries(section: "savedResults", appUser: $userid, id: $itemid, limit: 1) {
                id
            }
        }`,
    variables: {
      userid: parseInt(userid),
      itemid: parseInt(itemid),
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data );

  if (response.data.data.entries.length === 0) {
    // couldn't find saved item
    throw new Error("Couldn't find saved item");
  }

  const graphqlQuery2 = {
    operationName: "deleteSavedResultItem",
    query: `mutation deleteSavedResultItem( $id: Int! ) {
            deleteEntry(id: $id ) 
        }`,
    variables: {
      id: parseInt(itemid),
    },
  };
  const response2 = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery2,
  });
  // console.log( response2.data );
  return response2;
}

/*
    delete saved item
    param: user id, item to delete save
    return: nothing, let's assume it worked
*/
export async function deleteSavedItem(userid, itemid) {
  const graphqlQuery = {
    operationName: "getSavedId",
    query: `query getSavedId( $userid: [QueryArgument], $itemid: [QueryArgument] ) {
            entries(section: "saved", appUser: $userid, savedItem: $itemid, limit: 1) {
                id
            }
        }`,
    variables: {
      userid: parseInt(userid),
      itemid: parseInt(itemid),
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data );

  if (response.data.data.entries.length === 0) {
    // couldn't find saved item
    return false;
  }

  const graphqlQuery2 = {
    operationName: "deleteSavedItem",
    query: `mutation deleteSavedItem( $id: Int! ) {
            deleteEntry( id: $id ) 
        }`,
    variables: {
      id: parseInt(response.data.data.entries[0].id),
    },
  };
  const response2 = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery2,
  });
  // console.log( response2.data );
  return response2.data.data.deleteEntry;
}

/*
    get ALL user's saved items
    param: user id
    return: object of ALL saved items from that handle with En/Fr title, En/Fr desc, other info
*/
export async function getAllSaved(userid) {
  const graphqlQuery = {
    operationName: "getAllSaved",
    query: `query getAllSaved($userid: [QueryArgument]) {
            entries(section: "saved", appUser: $userid) {
                id
                ... on saved_default_Entry {
                    savedItemHandle
                    savedItem {
                      id
                    }
                }
            }
        }`,
    variables: {
      userid: userid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response.data.data.entries );

  if (response.data.data.entries.length === 0) {
    // couldn't find saved item
    return false;
  }

  const savedItemIds = response.data.data.entries.map(
    (item) => item.savedItem[0].id,
  );
  // console.log( {savedItemIds} );

  // take all those saved whatever ids and go collect the actual items
  const graphqlQuery2 = {
    operationName: "getEntriesById",
    query: `query getEntriesById($ids: [QueryArgument]) {
            entries(id: $ids) {
                id,
                title,
                sectionId,
                ... on quotes_default_Entry {
                    description_en
                    description_fr
                },
                ... on topics_articles_Entry {
                    title_fr
                    articleIcon {
                        url
                    }
                    pillar {
                        id
                    }
                },
                ... on resources_default_Entry {
                    title_fr
                    description_en
                    description_fr
                    phone
                    webURL
                    logo {
                        url
                    }
                }
            }
        }`,
    variables: {
      ids: savedItemIds,
    },
  };
  const response2 = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery2,
  });
  // console.log( response2.data.data.entries );
  return response2.data.data.entries;
}

/*
    get all user's saved items by item handle
    param: user id, item's handle (ie quotes, articles, resources)
    return: object of ALL saved items from that handle with En/Fr title, En/Fr desc, other info
*/
export async function getSavedResults(userid) {
  const graphqlQuery = {
    operationName: "getSavedResults",
    query: `query getSavedResults($userid: [QueryArgument]) {
            entries(section: "savedResults", appUser: $userid, savedItemHandle: "result") {
                id
                dateCreated
                ... on savedResults_default_Entry {
                    savedItemHandle
					resultsData
                    savedItem {
                      id
                      title
                    }
                }
            }
        }`,
    variables: {
      userid: userid,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( itemHandle, response.data.data.entries );
  return response.data.data.entries;
}

/*
    get all user's saved items by item handle
    param: user id, item's handle (ie quotes, articles, resources)
    return: object of ALL saved items from that handle with En/Fr title, En/Fr desc, other info
*/
export async function getSavedByItemHandle(userid, itemHandle) {
  const graphqlQuery = {
    operationName: "getSavedByItemHandle",
    query: `query getSavedByItemHandle($userid: [QueryArgument], $itemHandle: [QueryArgument]) {
            entries(section: "saved", appUser: $userid, savedItemHandle: $itemHandle) {
                id
                ... on saved_default_Entry {
                    savedItemHandle
                    savedItem {
                      id
                    }
                }
            }
        }`,
    variables: {
      userid: userid,
      itemHandle: itemHandle,
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log(itemHandle, response.data.data.entries);

  if (response.data.data.entries.length === 0) {
    // couldn't find saved item
    return false;
  }

  const savedItemIds = response.data.data.entries
    .map((item) => (item.savedItem.length > 0 ? item.savedItem[0].id : null))
    .filter((item) => item !== null);
  // console.log( itemHandle, {savedItemIds} );

  // take all those saved whatever ids and go collect the actual items
  let graphqlFields = "";
  switch (itemHandle) {
    case "quotes":
      graphqlFields = `
            ... on quotes_default_Entry {
                description_en
                description_fr
            }`;
      break;
    case "topics":
      graphqlFields = `
            ... on topics_wheelActivity_Entry {
                title_en: title
                title_fr
                articleIcon {
                    url
                }
                pillar {
                    id
                }
            }
            ... on topics_multiQuiz_Entry {
                title_en: title
                title_fr
                articleIcon {
                    url
                }
                pillar {
                    id
                }
            }
            ... on topics_quiz_Entry {
                title_en: title
                title_fr
                articleIcon {
                    url
                }
                pillar {
                    id
                }
            }
            ... on topics_articles_Entry {
                title_en: title
                title_fr
                articleIcon {
                    url
                }
                pillar {
                    id
                }
                articleBody_en {
                  ... on articleBody_en_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_en_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
                articleBody_fr {
                  ... on articleBody_fr_bodyContent_BlockType {
                    id
                    typeHandle
                    bodyContent
                  }
                  ... on articleBody_fr_bodyAccordion_BlockType {
                    id
                    typeHandle
                    accordionTitle
                    accordionContent
                  }
                }
            }`;
      break;
    case "resources":
      graphqlFields = `
            title_en: title
            ... on resources_default_Entry {
                title_fr
                description_en
                description_fr
                phone
                webURL
                tags
                logo {
                    url
                }
            }
            `;
      break;
    default:
      break;
  }
  const graphqlQuery2 = {
    operationName: "getEntriesById",
    query: `query getEntriesById($ids: [QueryArgument]) {
            entries(id: $ids) {
                id, 
                typeHandle,
                title
                ${graphqlFields}
            }
        }`,
    variables: {
      ids: savedItemIds,
    },
  };
  const response2 = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery2,
  });
  // console.log( response2.data.data.entries );
  return response2.data.data.entries;
}

/*
    delete account (want to keep for tracking etc so just clear out username)
    param: user id
    return: nothing, assume it works
*/
export async function closeAccount(userid) {
  var currentdate = new Date();

  const graphqlQuery = {
    operationName: "closeAccount",
    query: `mutation closeAccount($userid: ID, $username: String) {
            save_appUsers_default_Entry(id: $userid, appUsername: $username) {
              id
              title
              appUsername
            }
          }
          `,
    variables: {
      userid: userid,
      username:
        "closed " +
        currentdate.getFullYear() +
        "-" +
        ("0" + (currentdate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + currentdate.getDate()).slice(-2),
    },
  };
  const response = await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return response.data;
}

/*
    get json file from cms upload
    param: url to json file
    return: json data
*/
export async function getJson(path) {
  const response = await axios({
    url: path,
    method: "get",
    headers: headers,
  });
  // console.log( response.data );
  return response.data;
}

/*
    submit user feedback
    param: object of user answers
    returns: nothing
*/
export async function submitFeedback(feedback, userid) {
  const graphqlQuery = {
    operationName: "submitFeedback",
    query: `mutation submitFeedback( $userid: [Int], $feedback1: String, $feedback2: String, $feedback3: String, $feedback4: String, $feedback5: String, $feedbackComments: String) {
            save_feedback_default_Entry(
                authorId: "${process.env.REACT_APP_APPUSER_AUTHORID}", 
                appUser: $userid, 
                feedback1: $feedback1, 
                feedback2: $feedback2,
                feedback3: $feedback3,
                feedback4: $feedback4,
                feedback5: $feedback5,
                feedbackComments: $feedbackComments
            ) {
                id
                title
            }
          }`,
    variables: {
      feedback1: feedback.feedback1,
      feedback2: feedback.feedback2,
      feedback3: feedback.feedback3,
      feedback4: feedback.feedback4,
      feedback5: feedback.feedback5,
      feedbackComments: feedback.feedbackComments,
      userid: parseInt(userid),
    },
  };
  await axios({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    method: "post",
    headers: headers,
    data: graphqlQuery,
  });
  // console.log( response );
  return true;
}
