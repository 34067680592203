import React, { useState, useEffect, useContext } from "react";
import { Page, Button, Carousel, CarouselItem } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import {
  getJson,
  createSavedResultItem,
  deleteSavedResultItem,
} from "../../GraphqlService.js";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BottomBar from "../Homescreen/BottomBar";
import Pillar from "./Pillar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
//import SignUp from './SignUp';

import "./DefaultQuiz.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import { isResultSaveable } from "../../util.js";

// quiz activity- asks user series of questions on each page, user has to select one before going to next screen
function DefaultQuiz(props) {
  // console.log( props );

  const { parentCallback, pillarItem, activityInfo, navigator, fromSaved } =
    props;
  console.log("defaultquiz fromsaved", fromSaved);

  const iHeal = useContext(IhealContext);

  const [carouselIndex, setCarouselIndex] = useState(1);

  const [savedResult, setSavedResult] = useState(false);
  const [savedResultId, setSavedResultId] = useState("");

  const [numOfScreens, setNumOfScreens] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);

  //tracks which button is selected out of multiple choices
  const [buttonSelected, setButtonSelected] = useState(null);
  const [optionSelected, setOptionSelected] = useState(false);

  const [quizScore, setQuizScore] = useState(0);
  const [quizData, setQuizData] = useState();
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);

  const quizDataPath =
    activityInfo === undefined
      ? pillarItem.activityData
      : activityInfo.activityData;
  const eventTracker =
    activityInfo === undefined
      ? GaEventTracker(pillarItem.title_en + " > Warm Up Activity")
      : GaEventTracker(pillarItem.title_en + " > " + activityInfo.title_en);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getQuizData = async (path) => {
      try {
        getJson(path).then((res) => {
          if (isMounted) {
            setQuizData(res);
            setNumOfScreens(res.questions.length);
            setQuizDataLoaded(true);
          }
        });
      } catch (error) {
        console.error("DefaultQuiz getQuizData", error.message);
      }
    };
    getQuizData(quizDataPath);

    return () => {
      isMounted = false;
    };
  }, [quizDataPath]);

  const next = () => {
    // console.log(carouselIndex);
    var carousel2 = document.getElementById("carousel2");
    if (carouselIndex === numOfScreens) {
      // console.log("NO MORE QUESTIONS LEFT")
      setShowEndScreen(true);
      return;
    } else {
      carousel2.next();
      return;
    }
  };

  const carouselUpdate = (e) => {
    var scrollDiv = document.querySelector(".default-quiz .page__content");
    scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setOptionSelected(false);
    // console.log(e);
    setCarouselIndex(e.activeIndex + 1);
  };

  const finishActivity = () => {
    //note: can also reference navigator by calling it directly, instead of passing thru props
    //let myNavigator = document.querySelector('ons-navigator');

    navigator.resetPage({
      component: fromSaved ? Saved : Pillar,
      key: "pillar-screen",
      props: { pillarItem: pillarItem },
    });
  };

  const restartActivity = () => {
    let myNavigator = document.querySelector("ons-navigator");
    myNavigator.popPage();
  };

  //todo: keep track of all options selected in order to display final dynamic summary text at end of activity
  const optionChosen = (buttonId, value, question, answer) => {
    setButtonSelected(buttonId);
    setOptionSelected(true);
    setQuizScore(quizScore + value);
    eventTracker(question, answer, iHeal.userInfo.userid);
  };

  //cms needs to support variable number of questions, with each question having multiple possible answers. Each answer may also have a different weighting attached to it.
  function DisplayQuizData(quizData) {
    return (
      <Carousel
        fullscreen
        id="carousel2"
        onPostChange={(e) => carouselUpdate(e)}
      >
        {quizData &&
          quizData.questions.map((item) => {
            return (
              <CarouselItem key={item.id} className="carousel2-item">
                <p className="center">
                  {item[`title_${iHeal.userInfo.language}`]}
                </p>
                <br />
                <br />
                {item.answers.map((subitem) => {
                  return (
                    <Button
                      key={subitem.id}
                      className={`rounded-button rounded-ends rounded-button-outline full-width quiz-button ${
                        buttonSelected === subitem.id ? "active-button" : ""
                      }`}
                      onClick={() =>
                        optionChosen(
                          subitem.id,
                          subitem.value,
                          item.title_en,
                          subitem.text_en,
                        )
                      }
                    >
                      {subitem[`text_${iHeal.userInfo.language}`]}
                    </Button>
                  );
                })}
              </CarouselItem>
            );
          })}
      </Carousel>
    );
  }

  function SaveQuizResult(item) {
    if (savedResult) {
      deleteSavedResultItem(iHeal.userInfo.userid, savedResultId)
        .then((res) => {
          setSavedResult(false);
          setSavedResultId("");
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const resultText = { en: item["text_en"], fr: item["text_fr"] };
      if ("score_range_en" in item) {
        for (const lang of ["en", "fr"]) {
          resultText[lang] =
            '<span class="score-range">' +
            item[`score_range_${lang}`].replace("<SCORE>", quizScore) +
            "</span>" +
            resultText[lang];
        }
      }
      createSavedResultItem(
        iHeal.userInfo.userid,
        activityInfo.id,
        "result",
        JSON.stringify(resultText),
      )
        .then((res) => {
          setSavedResult(true);
          console.log(res.data);
          setSavedResultId(res.data.data.save_savedResults_default_Entry.id);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function DisplayQuizResult(quizData) {
    return (
      quizData &&
      quizData.results.map((item) => {
        if (quizScore >= item.low_score && quizScore <= item.high_score) {
          return (
            <>
              {activityInfo &&
                activityInfo.id &&
                isResultSaveable(activityInfo.id) && (
                  <FontAwesomeIcon
                    icon={savedResult ? faHeart : faHeartOutline}
                    onClick={() => SaveQuizResult(item)}
                    style={{ marginTop: "16px" }}
                  />
                )}
              {`score_range_${iHeal.userInfo.language}` in item && (
                <span className="score-range">
                  {item[`score_range_${iHeal.userInfo.language}`].replace(
                    "<SCORE>",
                    quizScore,
                  )}
                </span>
              )}
              <div
                key={item.id}
                className="activity-description"
                dangerouslySetInnerHTML={{
                  __html: item[`text_${iHeal.userInfo.language}`],
                }}
              />
              {activityInfo &&
                activityInfo.id &&
                isResultSaveable(activityInfo.id) && (
                  <>
                    <br />
                    <Button
                      className="rounded-button rounded-ends full-width"
                      style={{ color: pillarItem.pillarColourHex }}
                      onClick={() => SaveQuizResult(item)}
                    >
                      {savedResult
                        ? lang.activities_unsaveResult[iHeal.userInfo.language]
                        : lang.activities_saveResult[iHeal.userInfo.language]}
                    </Button>
                  </>
                )}
            </>
          );
        }
      })
    );
  }

  function handleCallback(childData) {
    let screenName = childData;
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({
          component: Saved,
          props: { key: "saved-screen" },
        });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`default-quiz ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {!showEndScreen ? (
            <>
              <div className="quiz-questions-label">
                {lang.activities_question[iHeal.userInfo.language]}{" "}
                {carouselIndex}/{numOfScreens}
                <br />
                <div className="full-width quiz-questions-progress-holder">
                  <div
                    className="quiz-questions-progress"
                    style={{
                      backgroundColor: pillarItem.pillarColourHex,
                      width: (carouselIndex / numOfScreens) * 100 + "%",
                    }}
                    onClick={() =>
                      console.log(document.querySelector("ons-navigator").pages)
                    }
                  />
                </div>
              </div>

              {quizDataLoaded ? (
                <div style={{ width: "100%" }}>{DisplayQuizData(quizData)}</div>
              ) : (
                <p>{lang.common_loading[iHeal.userInfo.language]}</p>
              )}

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                disabled={!optionSelected}
                onClick={() => next()}
              >
                {lang.common_next[iHeal.userInfo.language]}
              </Button>
            </>
          ) : (
            <>
              {DisplayQuizResult(quizData)}

              <br />

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                onClick={() => finishActivity()}
              >
                {lang.activities_finish[iHeal.userInfo.language]}
              </Button>
              <Button
                className="rounded-button rounded-ends full-width no-outline-button"
                onClick={() => restartActivity()}
              >
                {lang.activities_redo[iHeal.userInfo.language]}
              </Button>
            </>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default DefaultQuiz;
