import React, { useState, useEffect, useContext } from "react";
import { Page, Button, Carousel, CarouselItem } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import { submitFeedback } from "../../GraphqlService.js";

import BottomBar from "../Homescreen/BottomBar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";

import "../Pillars/DefaultQuiz.scss";
import "./FeedbackQuestions.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import feedbackData from "../../Assets/data/feedback.json";

function FeedbackQuestions(props) {
  // console.log( props );

  const iHeal = useContext(IhealContext);

  const [carouselIndex, setCarouselIndex] = useState(1);

  const [numOfScreens, setNumOfScreens] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);

  //tracks which button is selected out of multiple choices
  const [buttonSelected, setButtonSelected] = useState(null);
  const [optionSelected, setOptionSelected] = useState(false);
  const [comment, setComment] = useState("");

  const [feedback, setFeedback] = useState({});
  const [quizData, setQuizData] = useState(feedbackData);
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);

  const eventTracker = GaEventTracker("Feedback");

  useEffect(() => {
    setNumOfScreens(quizData.questions.length);
    setQuizDataLoaded(true);
  }, [quizData.questions.length]);

  const next = () => {
    // console.log(carouselIndex);
    var carousel2 = document.getElementById("carousel2");
    if (carouselIndex === numOfScreens) {
      // console.log("NO MORE QUESTIONS LEFT");
      setShowEndScreen(true);

      eventTracker("submitted", "", iHeal.userInfo.userid);
      // send all feedback to cms
      try {
        submitFeedback(feedback, iHeal.userInfo.userid).then((res) => {
          // console.log( res );
        });
      } catch (error) {
        console.error("FeedbackQuestions submitFeedback", error.message);
      }

      // props.navigator.resetPage({component: Home, key: 'home-screen'});
      return;
    } else {
      carousel2.next();
      return;
    }
  };

  const carouselUpdate = (e) => {
    var scrollDiv = document.querySelector(
      ".default-quiz.setting-screen .page__content",
    );
    scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // console.log(e);
    setCarouselIndex(e.activeIndex + 1);
    if (numOfScreens === e.activeIndex + 1) {
      // if showing feedback comment screen, let user submit without adding comment
      setOptionSelected(true);
    } else {
      setOptionSelected(false);
    }
  };

  const finishActivity = () => {
    props.navigator.resetPage({ component: Home, key: "home-screen" });
  };

  const restartActivity = () => {
    let myNavigator = document.querySelector("ons-navigator");
    myNavigator.popPage();
  };

  //todo: keep track of all options selected in order to display final dynamic summary text at end of activity
  const optionChosen = (buttonId, value, question, answer, fieldHandle) => {
    setButtonSelected(buttonId);
    setOptionSelected(true);

    eventTracker(question, answer, iHeal.userInfo.userid);

    setFeedback({ ...feedback, [fieldHandle]: answer });
  };

  //cms needs to support variable number of questions, with each question having multiple possible answers. Each answer may also have a different weighting attached to it.
  function DisplayQuizData() {
    // console.log( quizData );
    return (
      <Carousel
        fullscreen
        id="carousel2"
        onPostChange={(e) => carouselUpdate(e)}
      >
        {quizData &&
          quizData.questions.map((item) => {
            return (
              <CarouselItem key={item.id} className="carousel2-item">
                <p
                  className="center"
                  dangerouslySetInnerHTML={{
                    __html: item[`title_${iHeal.userInfo.language}`],
                  }}
                />
                {MultiOrComment(item)}
              </CarouselItem>
            );
          })}
      </Carousel>
    );
  }
  function handleComment(e) {
    setComment(e.target.value);
    setFeedback({ ...feedback, feedbackComments: e.target.value });
  }
  function MultiOrComment(feedbackItem) {
    if (feedbackItem.fieldHandle === "feedbackComments") {
      return (
        <textarea
          className="text-input--material"
          value={comment}
          onChange={(e) => handleComment(e)}
          modifier="material"
          placeholder="Comment"
        />
      );
    } else {
      return feedbackItem.answers.map((subitem) => {
        return (
          <Button
            key={subitem.id}
            className={`rounded-button rounded-ends rounded-button-outline full-width quiz-button`}
            style={{
              backgroundColor:
                buttonSelected === subitem.id ? "rgb(242, 242, 242, 0.5)" : "",
            }}
            onClick={() =>
              optionChosen(
                subitem.id,
                subitem.value,
                feedbackItem.title_en,
                subitem.text_en,
                feedbackItem.fieldHandle,
              )
            }
          >
            {subitem[`text_${iHeal.userInfo.language}`]}
          </Button>
        );
      });
    }
  }

  function DisplayQuizResult() {
    return (
      <div
        key={quizData.results[0].id}
        className="activity-description"
        dangerouslySetInnerHTML={{
          __html: quizData.results[0][`text_${iHeal.userInfo.language}`],
        }}
      />
    );
  }

  function handleCallback(childData) {
    let screenName = childData;
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className="default-quiz setting-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {!showEndScreen ? (
            <>
              <div className="quiz-questions-label">
                {lang.activities_question[iHeal.userInfo.language]}{" "}
                {carouselIndex}/{numOfScreens}
                <br />
                <div className="full-width quiz-questions-progress-holder">
                  <div
                    className="quiz-questions-progress"
                    style={{
                      backgroundColor: "rgb(19, 162, 220)",
                      width: (carouselIndex / numOfScreens) * 100 + "%",
                    }}
                    onClick={() =>
                      console.log(document.querySelector("ons-navigator").pages)
                    }
                  />
                </div>
              </div>

              {quizDataLoaded ? (
                <div style={{ width: "100%" }}>{DisplayQuizData()}</div>
              ) : (
                <p>{lang.common_loading[iHeal.userInfo.language]}</p>
              )}

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: "rgb(19, 162, 220)" }}
                disabled={!optionSelected}
                onClick={() => next()}
              >
                {carouselIndex === numOfScreens
                  ? lang.common_submit[iHeal.userInfo.language]
                  : lang.common_next[iHeal.userInfo.language]}
              </Button>
            </>
          ) : (
            <>
              {DisplayQuizResult()}

              <br />

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: "rgb(19, 162, 220)" }}
                onClick={() => finishActivity()}
              >
                {lang.activities_finish[iHeal.userInfo.language]}
              </Button>
              <Button
                className="rounded-button rounded-ends full-width no-outline-button"
                style={{ color: "rgb(0, 0, 0)" }}
                onClick={() => restartActivity()}
              >
                {lang.activities_redo[iHeal.userInfo.language]}
              </Button>
            </>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default FeedbackQuestions;
