import React, { useState, useContext, useEffect } from "react";
import { Page, Button, Checkbox } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";

import "./SignUp.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import Home from "../Homescreen/Home";
import { getExitText } from "../../GraphqlService.js";

//info screen which displays 3 checkboxes user must click before continuing
export default class Info extends React.Component {
  constructor(props) {
    super(props);
    // console.log( this.props );

    this.state = {
      isCheck1: false,
      isCheck2: false,
      isCheck3: false,
      language: localStorage.getItem("language"),
      exitText: lang.info_check2[localStorage.getItem("language")],
    };
  }

  componentWillMount() {
    getExitText("accountCreationCheck", this.state.language)
      .then((res) => {
        this.setState({ exitText: res });
      })
      .catch((error) => {
        console.error("Info getExitText", error.message);
      });
  }

  pushPage() {
    const eventTracker = GaEventTracker("Info");
    eventTracker("start");
    // this.props.navigator.pushPage({component: Home, key: 'home-screen'});
    this.props.navigator.pushPage({
      component: Home,
      props: { key: "home-screen", userInfo: this.props.userInfo },
    });
  }

  handleClick(data) {
    var dataVal = data.toString();

    if (dataVal === "clear") {
      this.setState({ pinValue: "" });
      return;
    }
    if (this.state.pinValue.length <= 3) {
      this.setState({ pinValue: this.state.pinValue + dataVal });
      console.log("Key value: " + dataVal);
      //console.log("PinVal: " + this.state.pinValue);
    }
    if (dataVal === "enter") {
      let password = this.state.password;
      let username = this.state.username;

      if (!this.state.onboardedUser) {
        localStorage.setItem("password", password);
        localStorage.setItem("username", username);
        localStorage.setItem("onboardedUser", "true");
      }
    }
  }

  render() {
    return (
      <Page className="signup info">
        <div className="page-container-100 scroll-overflow">
          <div className="flex-scroll">
            <Header type="accountCreation" lang={this.state.language} />

            <div className="page-container-30">
              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center center jc-center">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: lang.info_title[this.state.language],
                    }}
                  />
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>

            <div className="page-container-60">
              <div className="flexbox">
                <div className="flexside flex-10"></div>
                <div className="flexside flex-80 flex-center jc-center">
                  <div className="flex-100 flexbox nowrap">
                    <Checkbox
                      input-id="check1"
                      onChange={() => {
                        this.setState({ isCheck1: !this.state.isCheck1 });
                      }}
                      modifier="material"
                    />
                    <label
                      htmlFor="check1"
                      dangerouslySetInnerHTML={{
                        __html: lang.info_check1[this.state.language],
                      }}
                    />
                  </div>
                  <br />
                  <div className="flex-100 flexbox nowrap">
                    <Checkbox
                      input-id="check2"
                      onChange={() => {
                        this.setState({ isCheck2: !this.state.isCheck2 });
                      }}
                      modifier="material"
                    />
                    <label
                      htmlFor="check2"
                      dangerouslySetInnerHTML={{
                        __html: this.state.exitText,
                      }}
                    />
                  </div>
                  <br />
                  <div className="flex-100 flexbox nowrap">
                    <Checkbox
                      input-id="check3"
                      onChange={() => {
                        this.setState({ isCheck3: !this.state.isCheck3 });
                      }}
                      modifier="material"
                    />
                    <label
                      htmlFor="check3"
                      dangerouslySetInnerHTML={{
                        __html: lang.info_check3[this.state.language],
                      }}
                    />
                  </div>
                </div>
                <div className="flexside flex-10"></div>
              </div>
            </div>

            <div className="page-container-10">
              {/* Use onsen default button
            then make custom buttons in separate style sheet*/}

              <div className="flexbox">
                <div className="flexside flex-20"></div>
                <div className="flexside flex-60 flex-center jc-center">
                  <Button
                    className="rounded-button rounded-ends full-width"
                    disabled={
                      !(
                        this.state.isCheck1 &&
                        this.state.isCheck2 &&
                        this.state.isCheck3
                      )
                    }
                    onClick={this.pushPage.bind(this)}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lang.common_start[this.state.language],
                      }}
                    />
                  </Button>
                </div>
                <div className="flexside flex-20"></div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
