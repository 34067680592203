import React, { useEffect, useState, useContext } from "react";
import { Page, Button, Carousel, CarouselItem, Icon } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";

import BottomBar from "../Homescreen/BottomBar";
import Pillar from "./Pillar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
//import SignUp from './SignUp';

import "./WheelSlideshow.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

import wheel_en from "../../Assets/images/power-wheel-en.png";
import wheel_fr from "../../Assets/images/power-wheel-fr.png";
// import image from '../../Assets/images/ola-home-office.png';
import wheelData from "../../Assets/data/wheelSlideshow.json";

function WheelSlideshow(props) {
  console.log(props);

  const { navigator, pillarItem, fromSaved } = props;

  const iHeal = useContext(IhealContext);
  const eventTracker = GaEventTracker(
    pillarItem.title_en + " > Power and Control wheel",
  );

  const [carouselIndex, setCarouselIndex] = useState(0);

  //todo: get num of screens for default pillar activity from database
  const [numOfScreens, setNumOfScreens] = useState(2);

  //once user goes through all the screens, we show the end screen which has a different layout
  const [showEndScreen, setShowEndScreen] = useState(false);

  const [buttonSelected, setButtonSelected] = useState(null);

  const [optionSelected, setOptionSelected] = useState(false);

  //temp solution to center wheel, asset change of wheel would be long term solution
  const [wheelRotation, setWheelRotation] = useState(-22);

  useEffect(() => {
    // Update the document title using the browser API
  });

  //const [wheelRotation, setWheelRotation] = useState(0);

  const next = () => {
    console.log("next");
    var wheelCarousel = document.getElementById("wheelCarousel");
    console.log(wheelCarousel);

    // console.log( carouselFiller );
    // console.log( wheelCarousel.getActiveIndex() );
    eventTracker(
      carouselFiller[wheelCarousel.getActiveIndex()].title_en,
      "next slide",
      iHeal.userInfo.userid,
    );

    //replace hardcoded number with actual num of screens meant to be in carousel
    //this activity style may appear in more places than just the power wheel activity
    if (wheelCarousel.getActiveIndex() === 7) {
      // console.log("NO MORE QUESTIONS LEFT")
      setShowEndScreen(true);
      return;
    } else {
      wheelCarousel.next();
      return;
    }
  };

  const previous = () => {
    var wheelCarousel = document.getElementById("wheelCarousel");

    eventTracker(
      carouselFiller[wheelCarousel.getActiveIndex()].title_en,
      "previous slide",
      iHeal.userInfo.userid,
    );

    if (wheelCarousel.getActiveIndex() !== 0) {
      wheelCarousel.prev();
      return;
    }
    return;
  };

  const carouselUpdate = (e) => {
    var wheelCarousel = document.getElementById("wheelCarousel");
    var wheel = document.getElementById("wheel");
    wheel.setAttribute(
      "style",
      `transform:rotate(${-45 * (wheelCarousel.getActiveIndex() - 1)}deg)`,
    );
    setCarouselIndex(wheelCarousel.getActiveIndex());

    setOptionSelected(false);
    // console.log(e);
  };

  const carouselRefresh = () => {};

  const finishActivity = () => {
    //navigator.resetPage({component: Home}, {animation: 'fade'});
    navigator.resetPage({
      component: fromSaved ? Saved : Pillar,
      key: "pillar-screen",
      props: { pillarItem: pillarItem },
    });
  };
  const handleCallback = (childData) => {
    let screenName = childData;
    // console.log(childData);
    switch (screenName) {
      case "home":
        props.navigator.resetPage({
          component: Home,
          key: "home-screen",
        });
        break;
      case "saved":
        props.navigator.pushPage({
          component: Saved,
          props: { key: "saved-screen" },
        });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  };

  const restartActivity = () => {
    let myNavigator = document.querySelector("ons-navigator");
    myNavigator.popPage();
  };

  const optionChosen = (buttonId) => {
    setButtonSelected(buttonId);
    setOptionSelected(true);
  };

  //cms needs to support variable number of questions, with each question having multiple possible answers. Each answer may also have a different weighting attached to it.
  const carouselFiller = wheelData;

  const carouselItems = carouselFiller.map((item, index) => {
    return (
      <CarouselItem
        key={item["title_" + iHeal.userInfo.language]}
        className="carousel2-item"
      >
        <h1 className="center">{item["title_" + iHeal.userInfo.language]}</h1>
        <h3 className="center">
          {item["question_" + iHeal.userInfo.language]}
        </h3>
        <ul className="listitem">
          {item.details.map((subitem, i) => {
            return (
              <li key={subitem["text_" + iHeal.userInfo.language]}>
                {subitem["text_" + iHeal.userInfo.language]}
              </li>
            );
          })}
        </ul>
      </CarouselItem>
    );
  });
  console.log("carouselItems", carouselItems);

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`default-quiz ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      {!showEndScreen ? (
        <div
          style={{
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              flexGrow: 1,
              flexShrink: 1,
              overflow: "hidden",
            }}
          >
            <div
              className="flexbox"
              style={{ flexWrap: "nowrap", width: "100%" }}
            >
              <div className="flexside flex-10 ai-center jc-center">
                <Icon
                  style={{
                    display: carouselIndex === 0 ? "none" : "",
                    verticalAlign: "middle",
                    zIndex: 10,
                  }}
                  onClick={() => previous()}
                  icon="fa-chevron-left"
                  size={26}
                  fixedWidth={false}
                />
              </div>

              <div className="flex-80 scroll-overflow fade-bottom">
                <Carousel
                  fullscreen
                  id="wheelCarousel"
                  onPostChange={(e) => carouselUpdate(e)}
                >
                  {carouselItems}
                </Carousel>
              </div>

              <div className="flexside flex-10 ai-center jc-center">
                <Icon
                  onClick={() => next()}
                  icon="fa-chevron-right"
                  size={26}
                  fixedWidth={false}
                  style={{
                    verticalAlign: "middle",
                    zIndex: 10,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="wheel-container">
            {/* this img tag is for sizing and layout - the real image is placed in a div on top of this */}
            <img
              className="wheel-image"
              alt="Wheel Activity"
              style={{ visibility: "hidden" }}
              src={iHeal.userInfo.language === "fr" ? wheel_fr : wheel_en}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                id="wheel"
                className="wheel-image"
                src={iHeal.userInfo.language === "fr" ? wheel_fr : wheel_en}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flexbox">
          <div className="flexside flex-5"></div>
          <div className="flexside flex-90 flex-left">
            {/* <div className='image-container'>
                <img className="activity-image" alt="Wheel Activity" src={image}/>
              </div> */}

            <br />

            <div
              className="activity-description"
              dangerouslySetInnerHTML={{
                __html: lang.wheelslideshow_endslide[iHeal.userInfo.language],
              }}
            />

            <br />

            <Button
              className="rounded-button rounded-ends full-width"
              style={{ color: pillarItem.pillarColourHex }}
              onClick={() => finishActivity()}
            >
              {lang.activities_finish[iHeal.userInfo.language]}
            </Button>
          </div>
          <div className="flexside flex-5"></div>
        </div>
      )}
    </Page>
  );
}
export default WheelSlideshow;
