import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";

const SaveHeart = (props) => {
  const { checkSaved, toggleSaved } = props;
  const [saved, setSaved] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkSaved().then((res) => {
      if (res !== undefined) {
        setLoading(false);
        setSaved(res);
      }
    });
  }, [checkSaved]);

  return (
    <FontAwesomeIcon
      style={{ cursor: toggleSaved !== undefined ? "pointer" : "inherit" }}
      icon={loading ? faSpinner : saved ? faHeart : faHeartOutline}
      spin={loading}
      onClick={() => {
        if (!loading && toggleSaved !== undefined) {
          setLoading(true);
          toggleSaved(saved).then((res) => {
            setLoading(false);
            setSaved((curr) => !curr);
          });
        }
      }}
    />
  );
};

export default SaveHeart;
