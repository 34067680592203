import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import ons from "onsenui";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Onsen UI Styling and Icons
//require('onsenui/css/onsen-css-components.css');
//require('onsenui/css/onsenui.css');

import "./Theme/fonts/Poppins/Poppins-Thin.ttf";
import "./Theme/fonts/Poppins/Poppins-ThinItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-ExtraLight.ttf";
import "./Theme/fonts/Poppins/Poppins-ExtraLightItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-Light.ttf";
import "./Theme/fonts/Poppins/Poppins-LightItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-Regular.ttf";
import "./Theme/fonts/Poppins/Poppins-Italic.ttf";
import "./Theme/fonts/Poppins/Poppins-Medium.ttf";
import "./Theme/fonts/Poppins/Poppins-MediumItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-SemiBold.ttf";
import "./Theme/fonts/Poppins/Poppins-SemiBoldItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-Bold.ttf";
import "./Theme/fonts/Poppins/Poppins-BoldItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-ExtraBold.ttf";
import "./Theme/fonts/Poppins/Poppins-ExtraBoldItalic.ttf";
import "./Theme/fonts/Poppins/Poppins-Black.ttf";
import "./Theme/fonts/Poppins/Poppins-BlackItalic.ttf";

require("./Theme/_colors.scss");
require("./Theme/_fonts.scss");
require("./Theme/onsen-css-components.css");
require("./Theme/onsenui.css");

//our custom theme which gets loaded last so it overrides onsenui stuff we don't need
require("./Theme/CustomTheme.scss");

//const express = require("express");
//const mysql = require("mysql");

if (ons.platform.isIPhoneX()) {
  document.documentElement.setAttribute("onsflag-iphonex-portrait", "");
  document.documentElement.setAttribute("onsflag-iphonex-landscape", "");
}

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root"),
  );
  serviceWorker.unregister();
};

if (window.cordova) {
  document.addEventListener("deviceready", startApp, false);
} else {
  startApp();
}
