import React, { useState, useEffect, useContext } from "react";
import { SearchInput } from "react-onsenui";

import IhealContext from "../../Context/IhealContext";
import { getResources } from "../../GraphqlService";

import lang from "../../Assets/data/translations.json";
import ResourceCard from "./ResourceCard";

function ResourcesList(props) {
  // console.log( props );
  // both Home and ReadingActivity send filterBy, only ReadingActivity sends articleId
  const filterBy = props.filterBy || "";
  const articleId = props.articleId || "";

  const iHeal = useContext(IhealContext);

  const [loadingResources, setLoadingResources] = useState(true);
  const [resources, setResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // fetchResources();
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;
    let alphaOrderBy = "title";
    if (iHeal.userInfo.language === "fr") alphaOrderBy += "_fr";

    setLoadingResources(true);
    try {
      getResources(
        iHeal.userInfo.province,
        alphaOrderBy,
        searchTerm,
        articleId,
      ).then((res) => {
        if (isMounted) setResources(res || []);
      });
    } catch (error) {
      console.error("Resources fetchResources", error.message);
    }
    setLoadingResources(false);

    return () => {
      isMounted = false;
    };
  }, [iHeal, filterBy, articleId, searchTerm]);

  const handleSearch = (term) => {
    // console.log( `search: ${term}` );
    // fetchResources( term );
    setSearchTerm(term);
  };

  return (
    <div style={{ width: "100%" }}>
      <p className="find-resource center">
        {lang.resources_intro[iHeal.userInfo.language]}
      </p>

      <SearchInput
        id="resource-search"
        placeholder=""
        modifier="material"
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />

      {loadingResources ? (
        <p>{lang.common_loading[iHeal.userInfo.language]}</p>
      ) : (
        <div>
          {resources.length ? (
            resources.map((item) => (
              <ResourceCard
                key={item.id}
                resourceInfo={item}
                lang={iHeal.userInfo.language}
              />
            ))
          ) : (
            <div style={{ margin: "8px", padding: "16px" }}>
              {lang.resources_noneFound[iHeal.userInfo.language]}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default ResourcesList;
