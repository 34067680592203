import React from "react";
import { Toolbar, ToolbarButton, BackButton } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";

import lang from "../../Assets/data/translations.json";

// login/intro assets
import logoLogin from "../../Assets/images/logo-login.png";
import bgHeaderLogin from "../../Assets/images/bg-header-login.png";

import logo from "../../Assets/images/logo.png";
import bgHeader from "../../Assets/images/bg-header.svg";

const openSideMenu = () => {
  var menu = document.getElementById("menu");
  menu.open();
};

const backHeader = () => {
  return (
    <Toolbar
      modifier="transparent cover-content noshadow"
      className="page-header"
      style={{ backgroundImage: `url(${bgHeader})` }}
    >
      <div className="left">
        <ToolbarButton>
          <BackButton />
        </ToolbarButton>
      </div>
      <div className="right">
        <ToolbarButton>
          <img src={logo} alt="iHeal" />
        </ToolbarButton>
      </div>
    </Toolbar>
  );
};
const homeHeader = () => {
  return (
    <Toolbar
      modifier="transparent cover-content noshadow"
      className="page-header clickthrough"
      style={{ backgroundImage: `url(${bgHeader})` }}
    >
      <div className="left">
        <ToolbarButton>
          <img src={logo} alt="iHeal" />
        </ToolbarButton>
      </div>
      <div className="right">
        <ToolbarButton>
          {/* <img className='account-icon' src={account} onClick={() => openSideMenu()} alt="account-icon"/> */}
          <div id="bars" className="mouseclick" onClick={() => openSideMenu()}>
            <FontAwesomeIcon icon={faUser} />
            <FontAwesomeIcon icon={faBars} />
          </div>
        </ToolbarButton>
      </div>
    </Toolbar>
  );
};

const loginHeader = (props) => {
  return (
    <header
      className={`${props.type === "login" ? "page-container-30 " : ""}page-header`}
      style={{
        backgroundImage: `url(${props.type === "login" ? bgHeaderLogin : bgHeader})`,
      }}
    >
      <div className="flexbox">
        <div className="flex-5"></div>

        {props.type === "login" ? (
          <div className="flexside page-header flex-90 flex-center center jc-center">
            <div className="image-container">
              <img className="login-image" src={logoLogin} alt="iHeal" />
            </div>
            <h2>Safety. Health. Hope.</h2>
          </div>
        ) : (
          <div className="flexbox page-header flex-90 flex-center center jc-center jc-spacebetween">
            <div className="image-container flex-20">
              <img src={logo} alt="iHeal" />
            </div>
            <div>
              {props.type === "accountCreation"
                ? lang.commonHeader_accountCreation[props.lang]
                : lang.commonHeader_intro[props.lang]}
            </div>
          </div>
        )}

        <div className="flex-5"></div>
      </div>
    </header>
  );
};

const switchHeader = (props) => {
  let headerCode;

  switch (props.type) {
    case "back":
      headerCode = backHeader();
      break;
    case "home":
      headerCode = homeHeader();
      break;
    case "login":
    case "accountCreation":
    case "intro":
      headerCode = loginHeader(props);
      break;
    default:
      break;
  }

  return headerCode;
};

function Header(props) {
  // console.log( props );

  return <>{switchHeader(props)}</>;
}

export default Header;
