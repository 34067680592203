import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import IhealContext from "../../Context/IhealContext";
import {
  createSavedItem,
  deleteSavedItem,
  itemAlreadySaved,
} from "../../GraphqlService";

import language from "../../Assets/data/translations.json";

import "./ResourceCard.scss";
import SaveHeart from "./SaveHeart";

//resource card for resource info on homescreen
function ResourceCard(props) {
  // console.log( props );
  const { resourceInfo, lang, savedCallback } = props;

  const iHeal = useContext(IhealContext);

  function CheckResourceURLTitle() {
    if (resourceInfo.webURL !== null) {
      return (
        <div className="card-title">
          <a
            href={resourceInfo.webURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {resourceInfo["title_" + lang]}
          </a>
        </div>
      );
    }

    return <div className="card-title">{resourceInfo["title_" + lang]}</div>;
  }

  async function toggleSavedArticle(isSaved) {
    if (isSaved) {
      // delete saved item
      try {
        await deleteSavedItem(iHeal.userInfo.userid, resourceInfo.id).then(
          (res) => {
            if (res) {
              // if ResourceCard component called from Saved
              if (savedCallback !== undefined) {
                savedCallback();
              }
            } else {
              console.log("couldn't delete");
            }
          },
        );
      } catch (error) {
        console.error("ResourceCard deleteSavedItem", error.message);
      }
    } else {
      // create saved item
      await createSavedItem(
        iHeal.userInfo.userid,
        resourceInfo.id,
        "resources",
      );
    }
  }

  return (
    <div>
      <Card className="resource-card">
        <div className="flexbox">
          <CheckResourceURLTitle />
          <div className="resource-saved">
            <SaveHeart
              checkSaved={() =>
                itemAlreadySaved(iHeal.userInfo.userid, resourceInfo.id)
              }
              toggleSaved={toggleSavedArticle}
            />
          </div>
        </div>

        <div className="resource-info">
          {resourceInfo.phone && (
            <div className="card-phone">
              <a
                href={"tel:" + resourceInfo.phone}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faPhone} /> {resourceInfo.phone}
              </a>
            </div>
          )}
          {resourceInfo.webURL && (
            <div className="card-phone">
              <a
                href={resourceInfo.webURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />{" "}
                {language.resources_visitwebsite[lang]}
              </a>
            </div>
          )}
          <div
            className="card-description"
            dangerouslySetInnerHTML={{
              __html: resourceInfo["description_" + lang],
            }}
          />
        </div>
      </Card>
    </div>
  );
}
export default ResourceCard;
