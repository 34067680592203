import React, { useState, useEffect, useContext } from "react";
import { Page, Input, Button } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import {
  getDefaultBySlug,
  hashString,
  changePassword,
} from "../../GraphqlService.js";

import BottomBar from "../Homescreen/BottomBar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";

import "./Settings.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";

function ChangePassword(props) {
  const { navigator } = props;

  const eventTracker = GaEventTracker("Sidebar");
  const iHeal = useContext(IhealContext);

  const [defaultContent, setDefaultContent] = useState("");
  const [contentLoaded, setContentLoaded] = useState(false);
  const [gaTrackingDone, setGaTrackingDone] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordUpdateMessage, setPasswordUpdateMessage] = useState("");
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getContent = async () => {
      try {
        getDefaultBySlug("change-password").then((res) => {
          if (isMounted) {
            setDefaultContent(res);
            setContentLoaded(true);
          }
        });
      } catch (error) {
        console.error("ChangePassword getDefaultBySlug", error.message);
      }
    };
    getContent();

    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (
      oldPassword.length >= 8 &&
      password.length >= 8 &&
      password2.length >= 8 &&
      password === password2
    ) {
      setDisableUpdateButton(false);
    } else {
      setDisableUpdateButton(true);
    }
  }, [oldPassword, password, password2]);

  if (!gaTrackingDone) {
    eventTracker("Change Password", "", iHeal.userInfo.userid);
    setGaTrackingDone(true);
  }

  const handleCallback = (childData) => {
    let screenName = childData;
    console.log(childData);
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  };

  const updatePassword = (event) => {
    event.preventDefault();

    let successMsg =
      lang.sidebar_changePasswordSuccess[iHeal.userInfo.language];
    let failMsg = lang.sidebar_changePasswordFail[iHeal.userInfo.language];

    // console.log("account deleted");
    // closeAccount( iHeal.userInfo.userid );
    const checkPassword = async () => {
      try {
        changePassword(
          iHeal.userInfo.userid,
          hashString(oldPassword),
          hashString(password),
        ).then((res) => {
          if (res) {
            eventTracker("Change Password", "success", iHeal.userInfo.userid);
            setPasswordUpdateMessage(successMsg);
            setOldPassword("");
            document.getElementById("old-password").value = "";
            setPassword("");
            document.getElementById("password").value = "";
            setPassword2("");
            document.getElementById("password2").value = "";
          } else {
            eventTracker("Change Password", "failed", iHeal.userInfo.userid);
            setPasswordUpdateMessage(failMsg);
          }
        });
      } catch (error) {
        console.error("ChangePassword getDefaultBySlug", error.message);
      }
    };
    checkPassword();
  };

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className="setting-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {contentLoaded ? (
            <>
              <h1 className="center-text pillar-title">
                {defaultContent["title_" + iHeal.userInfo.language]}
              </h1>

              <div
                dangerouslySetInnerHTML={{
                  __html: defaultContent["body_" + iHeal.userInfo.language],
                }}
              />
            </>
          ) : (
            <p>{lang.common_loading[iHeal.userInfo.language]}</p>
          )}

          {passwordUpdateMessage === "" ? (
            <div></div>
          ) : (
            <div style={{ color: "#ff2f2f", marginBottom: "30px" }}>
              {passwordUpdateMessage}
              <br />
            </div>
          )}
          <form className="flex-100 flexside" onSubmit={updatePassword}>
            <div className="container" style={{ margin: "0 auto" }}>
              <div className="input-field">
                <FontAwesomeIcon icon={faKey} />
                <Input
                  value={oldPassword}
                  inputId="old-password"
                  onInput={(e) => setOldPassword(e.target.value)}
                  modifier="material"
                  placeholder={
                    lang.signup_placeholderCurrentPassword[
                      iHeal.userInfo.language
                    ]
                  }
                  type="password"
                />
              </div>
              <div className="input-field">
                <FontAwesomeIcon icon={faKey} />
                <Input
                  value={password}
                  inputId="password"
                  onInput={(e) => setPassword(e.target.value)}
                  modifier="material"
                  placeholder={
                    lang.signup_placeholderPassword[iHeal.userInfo.language]
                  }
                  type="password"
                />
              </div>
              <div className="input-field">
                <FontAwesomeIcon icon={faKey} />
                <Input
                  value={password2}
                  inputId="password2"
                  onInput={(e) => setPassword2(e.target.value)}
                  modifier="material"
                  placeholder={
                    lang.signup_placeholderConfirmPassword[
                      iHeal.userInfo.language
                    ]
                  }
                  type="password"
                />
              </div>

              <div style={{ fontWeight: "bold" }}>
                {lang.signup_passwordMessage[iHeal.userInfo.language]}
              </div>
            </div>
            <br />
            <Button
              type="submit"
              className="button rounded-button rounded-ends full-width"
              style={{ color: "rgb(19, 162, 220)" }}
              disabled={disableUpdateButton}
            >
              {lang.common_update[iHeal.userInfo.language]}
            </Button>
          </form>
          <br />
          <br />
          <Button
            className="rounded-button rounded-ends full-width"
            style={{ color: "rgb(19, 162, 220)", marginTop: "1rem" }}
            onClick={() => navigator.popPage()}
          >
            {lang.common_goBack[iHeal.userInfo.language]}
          </Button>
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default ChangePassword;
