import React, { useContext, useState, useEffect } from "react";
import { BottomToolbar } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faHeart,
  faHandshakeAngle,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { getExitURL } from "../../GraphqlService";

import "./BottomBar.scss";

import lang from "../../Assets/data/translations.json";

import IhealContext from "../../Context/IhealContext";

function BottomBar(props) {
  const iHeal = useContext(IhealContext);
  const [exitURL, setExitURL] = useState(
    "https://www.theweathernetwork.com/ca", // fallback url if CMS is slow/failing
  );

  useEffect(() => {
    getExitURL()
      .then((res) => {
        setExitURL(res);
      })
      .catch((err) => {
        console.error("falling back to default url", err);
      });
  }, []);

  //has parent handle the navigation callback so navigation stack doesn't get messed up
  function navScreen(screen) {
    //this.props.navigator.pushPage({component: Pillar});
    props.parentCallback(screen);
  }

  function handleExit() {
    // window.close() cannot close tabs that were not opened via script - best alternative we have
    window.location.replace(exitURL);
  }

  return (
    <BottomToolbar modifier="transparent" id="bottom-bar">
      <div className="flexbox jc-spacebetween">
        {/* home tab */}
        <div
          className="mouseclick bottombar-item flexside footer-home ai-center jc-center"
          onClick={() => navScreen("home")}
        >
          <FontAwesomeIcon icon={faHouse} />
          <div>{lang.footer_home[iHeal.userInfo.language]}</div>
        </div>
        {/* favourites */}
        <div
          className="mouseclick bottombar-item flexside footer-saved ai-center jc-center"
          onClick={() => navScreen("saved")}
        >
          <FontAwesomeIcon icon={faHeart} />
          <div>{lang.footer_saved[iHeal.userInfo.language]}</div>
        </div>
        {/* resources */}
        <div
          className="mouseclick bottombar-item flexside footer-resources ai-center jc-center"
          onClick={() => navScreen("resources")}
        >
          <FontAwesomeIcon icon={faHandshakeAngle} />
          <div>{lang.footer_resources[iHeal.userInfo.language]}</div>
        </div>
        {/* logout */}
        <div
          className="mouseclick bottombar-item flexside footer-exit ai-center jc-center"
          onClick={() => handleExit()}
        >
          <FontAwesomeIcon icon={faRightFromBracket} />
          <div>{lang.footer_exit[iHeal.userInfo.language]}</div>
        </div>
      </div>
    </BottomToolbar>
  );
}

export default BottomBar;
