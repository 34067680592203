import React, { useState, useEffect, useContext } from "react";
import { Page, Button } from "react-onsenui";

import IhealContext from "../../Context/IhealContext";
import {
  createSavedItem,
  deleteSavedItem,
  itemAlreadySaved,
  getPillarTopics,
} from "../../GraphqlService";

import BottomBar from "../Homescreen/BottomBar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
import DefaultQuiz from "./DefaultQuiz";
import ActivityIcon from "../Common/ActivityIcon";
//import SignUp from './SignUp';

import "./DefaultActivity.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import SaveHeart from "../Common/SaveHeart";

//the default activity that appears at the top of the pillar screen. Has title, description and next button
function DefaultActivity(props) {
  console.log(props);
  const { parentCallback, pillarItem, activityId, fromSaved, isDefault } =
    props;
  console.log("pillarItem", pillarItem);
  console.log("activityId", activityId);
  const iHeal = useContext(IhealContext);

  const [topicsLoaded, setTopicsLoaded] = useState(false);
  const [activityInfo, setActivityInfo] = useState(undefined);

  useEffect(() => {
    if (isDefault) {
      return;
    }
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    try {
      getPillarTopics(pillarItem.id, activityId).then((res) => {
        if (isMounted) {
          console.log("gql", res);
          setActivityInfo(res && res[0] ? res[0] : undefined);
          setTopicsLoaded(true);
          console.log(
            "DefaultActivity getPillarTopics",
            res && res[0] ? res[0] : undefined,
          );
        }
      });
    } catch (error) {
      console.error("Pillar getPillarTopics", error.message);
    }

    return () => {
      isMounted = false;
    };
  }, [pillarItem, activityId, isDefault, iHeal]);

  function pushPage() {
    props.navigator.pushPage({
      component: DefaultQuiz,
      key: "default-quiz-screen",
      props: { pillarItem: pillarItem, activityInfo: activityInfo, fromSaved },
    });
  }

  function handleCallback(childData) {
    let screenName = childData;
    console.log(childData);
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  async function toggleSavedArticle(isSaved) {
    if (isSaved) {
      // delete saved quote
      await deleteSavedItem(iHeal.userInfo.userid, activityInfo.id);
    } else {
      // create saved item
      await createSavedItem(iHeal.userInfo.userid, activityInfo.id, "topics");
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`default-activity ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          <div className="header-container full-width">
            <div className="flexbox" style={{ alignItems: "center" }}>
              <div className="flex1 activitySave">
                {activityInfo === undefined ? (
                  ""
                ) : (
                  <SaveHeart
                    checkSaved={() =>
                      itemAlreadySaved(iHeal.userInfo.userid, activityInfo.id)
                    }
                    toggleSaved={toggleSavedArticle}
                  />
                )}
              </div>
            </div>
          </div>

          {topicsLoaded || isDefault ? (
            <>
              {" "}
              {activityInfo === undefined ? (
                ""
              ) : (
                <div className="image-container">
                  <ActivityIcon
                    title=""
                    icon={activityInfo.articleIcon[0].url}
                  />
                </div>
              )}
              <h1 className="center-text pillar-title">
                {activityInfo === undefined
                  ? pillarItem["activityTitle_" + iHeal.userInfo.language]
                  : activityInfo["title_" + iHeal.userInfo.language]}
              </h1>
              {activityInfo === undefined ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      pillarItem[
                        "activityDescription_" + iHeal.userInfo.language
                      ],
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      activityInfo[
                        "activityDescription_" + iHeal.userInfo.language
                      ],
                  }}
                />
              )}
              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                onClick={pushPage.bind(this)}
              >
                {lang.common_next[iHeal.userInfo.language]}
              </Button>{" "}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default DefaultActivity;
