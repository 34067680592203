import React from "react";

import "./ActivityIcon.scss";

//activity icon component, also known as topics, can have activity label on it to signify a more elaborate activity is associated with it
//each activity has different types it can be -> leads to different screens
function ActivityIcon(props) {
  // console.log( props );
  const { title, icon } = props;

  return (
    <div id="activity-icon">
      <img src={icon} alt={title} className="activity-icon" />
    </div>
  );
}
export default ActivityIcon;
