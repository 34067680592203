import React, { useState, useEffect, useContext } from "react";
import { Page, Input, Button } from "react-onsenui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faKey,
  faRightToBracket,
  faHandHoldingHeart,
} from "@fortawesome/free-solid-svg-icons";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext";

import "./SignUp.scss";

import Header from "../Common/Header";
import Home from "../Homescreen/Home";
import Language from "./Language";

import { hashString, appuserLogin, getExitURL } from "../../GraphqlService";

//page handling the signup elements
//todo: hook up to backend user database
function Login(props) {
  const eventTracker = GaEventTracker("Login");

  const { userInfo, setUserInfo } = useContext(IhealContext);
  const [password, setPassword] = useState("");
  const [username, setusername] = useState("");
  const [exitURL, setExitURL] = useState(
    "https://www.theweathernetwork.com/ca", // fallback url if CMS is slow/failing
  );

  const [failedLogin, setFailedLogin] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(true);

  useEffect(() => {
    getExitURL()
      .then((res) => {
        setExitURL(res);
      })
      .catch((err) => {
        console.error("falling back to default url", err);
      });
  }, []);

  useEffect(() => {
    if (password.length >= 4 && username.length > 1) {
      setDisableNextButton(false);
    } else {
      setDisableNextButton(true);
    }
  }, [username, password]);

  const handleLogin = (event) => {
    event.preventDefault();
    setDisableNextButton(true);

    if (password === "1234") {
      // eventTracker( "emergency password" );
      // window.close() cannot close tabs that were not opened via script - best alternative we have
      window.location.replace(exitURL);
    }

    const userLogin = {
      username: hashString(username),
      password: hashString(password),
    };
    // console.log( userLogin );

    try {
      // console.log( 'login start' );
      appuserLogin(userLogin).then((res) => {
        // console.log( res );
        if (res === false) {
          eventTracker("fail");
          setFailedLogin(true);
          setDisableNextButton(false);
        } else {
          setFailedLogin(false);
          // console.log( res );
          // console.log( 'login success' );
          setUserInfo(res);
          // console.log( res );
          localStorage.setItem("language", res.language);
          eventTracker("success", "", res.userid);
          props.navigator.replacePage({
            component: Home,
            props: { key: "home-screen" },
          });
        }
      });
    } catch (error) {
      console.error("Login userLogin", error.message);
    }

    // props.navigator.pushPage({component: Info, props: {key: 'info-screen'}});
  };

  const handleClick = (event) => {
    eventTracker(event.target.id);
    if (event.target.id === "signup") {
      props.navigator.pushPage({
        component: Language,
        props: { key: "language-screen" },
      });
    }
    if (event.target.id === "support") {
      console.log("TODO: support");
    }
  };

  return (
    <Page className="signup login">
      <div className="page-container-100 scroll-overflow">
        <div className="flex-scroll">
          <Header type="login" />

          <div className="page-container-40">
            <div className="flexbox">
              <div className="flexside flex-15"></div>
              <div className="flexside flex-70 flex-center jc-center">
                <h1>
                  Welcome
                  <br />
                  Bienvenue
                </h1>
                <p>
                  If you are a new user, please click Create an Account
                  <br />
                  Si vous êtes un nouvel utilisateur, veuillez s'inscrirer
                </p>

                {failedLogin ? (
                  <div style={{ color: "#ff2f2f", marginBottom: "30px" }}>
                    Failed login / Veuillez réessayer
                    <br />
                  </div>
                ) : (
                  <></>
                )}

                <form className="flex-100 flexside" onSubmit={handleLogin}>
                  <div className="input-field full-width">
                    <FontAwesomeIcon icon={faUser} />
                    <Input
                      value={username}
                      onInput={(e) => setusername(e.target.value)}
                      modifier="material"
                      placeholder="Username / Nom d´utilisateur"
                    />
                  </div>
                  <div className="input-field full-width">
                    <FontAwesomeIcon icon={faKey} />
                    <Input
                      value={password}
                      onInput={(e) => setPassword(e.target.value)}
                      modifier="material"
                      placeholder="Password / Mot de passe"
                      type="password"
                    />
                  </div>

                  <button
                    type="submit"
                    modifier="quiet"
                    id="login"
                    className="button button--quiet flex-alignself-end"
                    disabled={disableNextButton}
                  >
                    <FontAwesomeIcon icon={faRightToBracket} /> login / Se
                    Connecter
                  </button>
                </form>
              </div>
              <div className="flexside flex-15"></div>
            </div>
          </div>

          <div className="page-container-30">
            <div className="flexbox">
              <div className="flexside flex-15"></div>
              <div className="flexside flex-70 flex-center jc-center">
                <Button modifier="quiet" id="signup" onClick={handleClick}>
                  <FontAwesomeIcon icon={faHandHoldingHeart} /> Create an
                  account / Créer un compte
                </Button>
              </div>
              <div className="flexside flex-15"></div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
export default Login;
