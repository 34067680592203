import React, { useState, useEffect, useContext } from "react";
import { Card } from "react-onsenui";

import IhealContext from "../../Context/IhealContext";
import { getPillars } from "../../GraphqlService";

import lang from "../../Assets/data/translations.json";
import PillarIcon from "../Common/PillarIcon";

function Pillars(props) {
  const { parentCallback, eventTracker } = props;
  // console.log( props );

  const iHeal = useContext(IhealContext);

  let pillarCollection = [];
  if (localStorage.getItem("pillarCollection")) {
    pillarCollection = JSON.parse(localStorage.getItem("pillarCollection"));
  }
  let pillarCollectionLength = pillarCollection.length;

  const [loadingPillars, setLoadingPillars] = useState(true);
  const [pillars, setPillars] = useState(pillarCollection);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    setLoadingPillars(true);
    if (pillarCollectionLength === 0) {
      try {
        getPillars().then((res) => {
          if (isMounted) {
            setPillars(res || []);
            localStorage.setItem("pillarCollection", JSON.stringify(res || []));
          }
        });
      } catch (error) {
        console.error("Pillars fetchPillars", error.message);
      }
    }
    setLoadingPillars(false);

    return () => {
      isMounted = false;
    };
  }, [pillarCollectionLength]);

  function pillarBG(slug) {
    let bg;

    switch (slug) {
      case "safety":
        bg =
          "linear-gradient(140deg, rgba(9, 127, 155, 1) 0%, rgba(24, 155, 158, 1) 0%, rgba(36, 176, 160, 1) 0%, rgba(40, 184, 161, 1) 0%, rgba(40, 181, 159, 1) 45.21%, rgba(41, 148, 132, 1) 73.89%, rgba(42, 122, 110, 1) 100%)";
        break;
      case "basic-needs":
        bg =
          "linear-gradient(-10deg, rgba(22, 102, 174, 1) 0%, rgba(21, 126, 193, 1) 12.74%, rgba(21, 146, 208, 1) 27.25%, rgba(20, 158, 218, 1) 41.8%, rgba(20, 162, 221, 1) 56.38%, rgba(107, 202, 207, 1) 99.82%, rgba(40, 181, 159, 1) 100%)";
        break;
      case "health-and-well-being":
        bg =
          "linear-gradient(140deg, rgba(254, 238, 122, 1) 0%, rgba(217, 225, 113, 1) 11.87%, rgba(160, 206, 99, 1) 28.19%, rgba(137, 188, 94, 1) 47.05%, rgba(80, 142, 80, 1) 86.29%, rgba(58, 125, 75, 1) 100%)";
        break;
      case "connecting-safely":
        bg =
          "linear-gradient(140deg, rgba(69, 52, 122, 1) 0%, rgba(139, 83, 152, 1) 64.1%, rgba(174, 98, 167, 1) 100%, rgba(192, 111, 172, 1) 100%, rgba(220, 131, 179, 1) 100%, rgba(238, 144, 183, 1) 100%, rgba(244, 148, 185, 1) 100%)";
        break;
      case "claiming-your-power":
        bg =
          "linear-gradient(140deg, rgba(245, 134, 114, 1) 0%, rgba(240, 85, 115, 1) 35.2%, rgba(199, 72, 105, 1) 67.26%, rgba(118, 47, 85, 1) 100%)";
        break;
      case "family":
        bg =
          "linear-gradient(140deg, rgba(254, 209, 61, 1) 0%, rgba(249, 157, 61, 1) 50.84%, rgba(239, 63, 99, 1) 99.46%)";
        break;
      default:
        break;
    }
    return { background: bg };
  }

  function handleClick(item) {
    // console.log( iHeal );
    eventTracker("pillar", item.title_en, iHeal.userInfo.userid);
    parentCallback("pillar", item);
  }

  return (
    <div className="flexbox jc-spacebetween">
      {loadingPillars ? (
        <p>{lang.common_loading[iHeal.userInfo.language]}</p>
      ) : (
        <>
          {pillars.length ? (
            pillars.map((item) => (
              <Card
                key={item.id}
                className="pillar-card card"
                style={pillarBG(item.slug)}
                onClick={() => handleClick(item)}
              >
                <div className="flexbox nowrap" style={{ height: "auto" }}>
                  <div>
                    <PillarIcon slug={item.slug} />
                  </div>
                  <div className="flex1 card-title">
                    {item["title_" + iHeal.userInfo.language]}
                  </div>
                </div>
                <div
                  className="card-description"
                  dangerouslySetInnerHTML={{
                    __html: item["excerpt_" + iHeal.userInfo.language],
                  }}
                />
              </Card>
            ))
          ) : (
            <div style={{ margin: "8px", padding: "16px" }}>No Pillars</div>
          )}
        </>
      )}
    </div>
  );
}
export default Pillars;
