import React, { useContext } from "react";
import { Page, Button } from "react-onsenui";

import IhealContext from "../../Context/IhealContext.js";

import BottomBar from "./BottomBar";
import Home from "./Home";
import Saved from "./Saved";
import ResourcesList from "../Common/ResourcesList";

import "../Common/ResourceCard.scss";
import "./Resources.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import PillarIcon from "../Common/PillarIcon";

function Resources(props) {
  const { navigator } = props;

  const iHeal = useContext(IhealContext);

  //function which handles that callback from the bottom tabbar + pillar selection
  //repeated in multiple places can probably be refactored out
  function handleCallback(screen) {
    // console.log( screen, pid );
    switch (screen) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className="resources-screen"
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>

        <div className="flexside flex-90 flex-left">
          <div className="pillar-icon">
            <PillarIcon slug="resources" />
          </div>
          <h1 className="center-text pillar-title">
            {lang.resources_title[iHeal.userInfo.language]}
          </h1>

          <ResourcesList />

          <br />
          <br />
          <Button
            className="rounded-button rounded-ends full-width"
            onClick={() => navigator.popPage()}
          >
            {lang.common_goBack[iHeal.userInfo.language]}
          </Button>
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}

export default Resources;
