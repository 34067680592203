import React, { useState, useEffect, useContext } from "react";
import { Page, Button, Carousel, CarouselItem } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import {
  getJson,
  createSavedResultItem,
  deleteSavedResultItem,
} from "../../GraphqlService.js";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";

import BottomBar from "../Homescreen/BottomBar";
import Pillar from "./Pillar";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
//import SignUp from './SignUp';

import "./MultiItemList.scss";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isResultSaveable } from "../../util.js";

// multi select activity- user is given question and selects multiple answers that apply, answers then all appear at end of activity
function MultiItemList(props) {
  // console.log( props );

  const { parentCallback, pillarItem, activityInfo, navigator, fromSaved } =
    props;

  const iHeal = useContext(IhealContext);

  const [savedResult, setSavedResult] = useState(false);
  const [savedResultId, setSavedResultId] = useState("");

  const [carouselIndex, setCarouselIndex] = useState(1);

  const [numOfScreens, setNumOfScreens] = useState(0);
  const [showEndScreen, setShowEndScreen] = useState(false);

  // options selected on current screen
  const [buttonsSelected, setButtonsSelected] = useState([]);
  const [optionSelected, setOptionSelected] = useState(false);
  const [optionNoneSelected, setOptionNoneSelected] = useState(false);

  const [quizScore, setQuizScore] = useState(0);
  const [quizData, setQuizData] = useState();
  const [quizDataLoaded, setQuizDataLoaded] = useState(false);

  const quizDataPath = activityInfo.activityData;
  const eventTracker = GaEventTracker(
    pillarItem.title_en + " > " + activityInfo.title_en,
  );

  //stores all options selected throughout activity, user gets shown list at end
  const [allOptions, setAllOptions] = useState([]);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    const getQuizData = async (path) => {
      try {
        getJson(path).then((res) => {
          if (isMounted) {
            setQuizData(res);
            setNumOfScreens(res.questions.length);
            setQuizDataLoaded(true);
          }
        });
      } catch (error) {
        console.error("MultiItemList getQuizData", error.message);
      }
    };
    getQuizData(quizDataPath);

    return () => {
      isMounted = false;
    };
  }, [quizDataPath]);

  const next = () => {
    var carousel2 = document.getElementById("carousel2");
    if (carouselIndex === numOfScreens) {
      // console.log("NO MORE QUESTIONS LEFT")
      setShowEndScreen(true);
      var scrollDiv = document.querySelector(".multi-item-list .page__content");
      scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    } else {
      carousel2.next();
      return;
    }
  };

  const carouselUpdate = (e) => {
    var scrollDiv = document.querySelector(".multi-item-list .page__content");
    scrollDiv.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    setButtonsSelected([]);
    setOptionSelected(false);
    setOptionNoneSelected(false);
    // console.log(e);
    setCarouselIndex(e.activeIndex + 1);
    // setAllOptionsBackup([...allOptions]);
    // console.log(allOptionsBackup);
  };

  const finishActivity = () => {
    // let myNavigator = document.querySelector('ons-navigator');
    // let pages = myNavigator.pages;

    //navigator.resetPage({component: Home}, {animation: 'fade'});
    // navigator.resetPage({component: Pillar, key: 'pillar-screen', props: {pillarName: pillarName, pillarIcon: pillarIcon, pillarColor: pillarColor}})
    navigator.resetPage({
      component: fromSaved ? Saved : Pillar,
      key: "pillar-screen",
      props: { pillarItem: pillarItem },
    });
  };

  const restartActivity = () => {
    let myNavigator = document.querySelector("ons-navigator");
    myNavigator.popPage();
  };

  const optionChosen = (buttonId, buttonValue, buttonText, question) => {
    if (buttonsSelected.includes(buttonId)) {
      /* OPTION UNCHECKED */
      eventTracker(question, "deselect " + buttonText, iHeal.userInfo.userid);

      if (buttonsSelected.length === 1) {
        setOptionSelected(false);
      }

      if (buttonText === "None") {
        setOptionNoneSelected(false);
      }

      setButtonsSelected((current) =>
        current.filter((btn) => {
          return btn !== buttonId;
        }),
      );
      setAllOptions((current) =>
        current.filter((btn) => {
          return btn !== buttonText;
        }),
      );
      setQuizScore(quizScore - buttonValue);
    } else {
      /* USER CLICK ON RESPONSE ITEM */
      eventTracker(question, buttonText, iHeal.userInfo.userid);
      setOptionSelected(true);
      setButtonsSelected((current) => [...current, buttonId]);

      if (buttonText === "None") {
        setOptionNoneSelected(true);
      } else {
        setAllOptions((current) => [...current, buttonText]);
      }
      setQuizScore(quizScore + buttonValue);
    }

    //if button is "none" then we unselect all the other items
    //and return the list of all items to state when carousel updated initially
    if (buttonText === "None") {
      // setButtonsSelected(["None"]);
      // setAllOptions([...allOptionsBackup]);
      // console.log(buttonsSelected);
      return;
    } else if (Object.values(buttonsSelected).includes("None")) {
      //if none was previously in the list then we replace it with the new selected value
      //list of all items if made to be initial state + the new button that was clicked
      // setButtonsSelected([buttonText]);
      // setAllOptions([...allOptionsBackup, buttonText]);
      return;
    }
  };
  const checkOptionDisabled = (value) => {
    // if this is "None" but another option has already been selected, disable
    if (value === 0 && !optionNoneSelected && buttonsSelected.length)
      return true;

    // if "None" selected, disable other options
    if (value !== 0 && optionNoneSelected) return true;
  };

  //cms needs to support variable number of questions, with each question having multiple possible answers. Each answer may also have a different weighting attached to it.
  function DisplayQuizData(quizData) {
    return (
      <Carousel
        fullscreen
        id="carousel2"
        onPostChange={(e) => carouselUpdate(e)}
      >
        {quizData &&
          quizData.questions.map((item) => {
            return (
              <CarouselItem key={item.id} className="carousel2-item">
                <p className="center">
                  {item[`title_${iHeal.userInfo.language}`]}
                </p>
                <p className="center">
                  {lang.activities_selectAll[iHeal.userInfo.language]}
                </p>
                <br />
                <br />
                {item.answers.map((subitem) => {
                  return (
                    <Button
                      key={subitem.id}
                      className={`rounded-button rounded-ends rounded-button-outline full-width quiz-button ${
                        buttonsSelected.includes(subitem.id)
                          ? "active-button"
                          : ""
                      }`}
                      disabled={checkOptionDisabled(subitem.value)}
                      onClick={() =>
                        optionChosen(
                          subitem.id,
                          subitem.value,
                          subitem[`text_${iHeal.userInfo.language}`],
                          item.title_en,
                        )
                      }
                    >
                      {subitem[`text_${iHeal.userInfo.language}`]}
                    </Button>
                  );
                })}
              </CarouselItem>
            );
          })}
      </Carousel>
    );
  }

  function SaveQuizResult(item, quizData, allOptions) {
    if (savedResult) {
      deleteSavedResultItem(iHeal.userInfo.userid, savedResultId)
        .then((res) => {
          setSavedResult(false);
          setSavedResultId("");
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const resultText = { en: item["text_en"], fr: item["text_fr"] };

      let allOptionsDisplay = { en: "", fr: "" };
      if (allOptions.length) {
        for (const lang of ["en", "fr"]) {
          const msgStart = quizData.result_heading[lang];
          allOptionsDisplay[lang] = "<p>" + msgStart + "</p>";
          // backgroundColor: pillarColor, borderColor: pillarColor
          allOptions.forEach(
            (optionItem) =>
              (allOptionsDisplay[lang] +=
                `<Button class="rounded-button rounded-ends rounded-button-outline list-button" style="background-color: ${pillarItem.pillarColourHex}; border-color: ${pillarItem.pillarColourHex};">${optionItem}</Button>`),
          );
          resultText[lang] = allOptionsDisplay[lang] + resultText[lang];
        }
      }

      createSavedResultItem(
        iHeal.userInfo.userid,
        activityInfo.id,
        "result",
        JSON.stringify(resultText),
      )
        .then((res) => {
          setSavedResult(true);
          console.log(res.data);
          setSavedResultId(res.data.data.save_savedResults_default_Entry.id);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  function DisplayQuizResult(quizData, allOptions) {
    let allOptionsDisplay = "";
    // const msgStart = lang.activities_saftyActionResults[ iHeal.userInfo.language ];
    const msgStart = quizData.result_heading[iHeal.userInfo.language];
    console.log(quizData);

    if (allOptions.length) {
      allOptionsDisplay = "<p>" + msgStart + "</p>";
      // backgroundColor: pillarColor, borderColor: pillarColor
      allOptions.forEach(
        (item) =>
          (allOptionsDisplay += `<Button class="rounded-button rounded-ends rounded-button-outline list-button" style="background-color: ${pillarItem.pillarColourHex}; border-color: ${pillarItem.pillarColourHex};">${item}</Button>`),
      );
    }

    return (
      quizData &&
      quizData.results.map((item) => {
        // console.log( `check ${quizScore} between ${item.low_score} --> ${item.high_score}` );
        if (quizScore >= item.low_score && quizScore <= item.high_score) {
          // console.log( 'found' );
          return (
            <>
              {activityInfo &&
                activityInfo.id &&
                isResultSaveable(activityInfo.id) && (
                  <FontAwesomeIcon
                    icon={savedResult ? faHeart : faHeartOutline}
                    onClick={() => SaveQuizResult(item, quizData, allOptions)}
                    style={{ marginTop: "16px" }}
                  />
                )}
              <div
                key="list"
                className="activity-description"
                dangerouslySetInnerHTML={{ __html: allOptionsDisplay }}
              />
              <div
                key={item.id}
                className="activity-description"
                dangerouslySetInnerHTML={{
                  __html: item[`text_${iHeal.userInfo.language}`],
                }}
              />
              {activityInfo &&
                activityInfo.id &&
                isResultSaveable(activityInfo.id) && (
                  <>
                    <br />
                    <Button
                      className="rounded-button rounded-ends full-width"
                      style={{ color: pillarItem.pillarColourHex }}
                      onClick={() => SaveQuizResult(item, quizData, allOptions)}
                    >
                      {savedResult
                        ? lang.activities_unsaveResult[iHeal.userInfo.language]
                        : lang.activities_saveResult[iHeal.userInfo.language]}
                    </Button>
                  </>
                )}
            </>
          );
        }
        return null;
      })
    );
  }

  function handleCallback(childData) {
    let screenName = childData;
    switch (screenName) {
      case "home":
        props.navigator.resetPage({ component: Home, key: "home-screen" });
        break;
      case "saved":
        props.navigator.pushPage({
          component: Saved,
          props: { key: "saved-screen" },
        });
        break;
      case "resources":
        props.navigator.pushPage({
          component: Resources,
          key: "resources-screen",
        });
        break;
      default:
        break;
    }
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`multi-item-list ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          {!showEndScreen ? (
            <>
              <div className="quiz-questions-label">
                {lang.activities_question[iHeal.userInfo.language]}{" "}
                {carouselIndex}/{numOfScreens}
                <br />
                <div className="full-width quiz-questions-progress-holder">
                  {/* <div className="quiz-questions-progress" style={{backgroundColor: pillarColor, width: ((carouselIndex/numOfScreens)*100) +"%"}} onClick={() => console.log(document.querySelector('ons-navigator').pages)}/> */}
                  <div
                    className="quiz-questions-progress"
                    style={{
                      backgroundColor: pillarItem.pillarColourHex,
                      width: (carouselIndex / numOfScreens) * 100 + "%",
                    }}
                    onClick={() =>
                      console.log(document.querySelector("ons-navigator").pages)
                    }
                  />
                </div>
              </div>

              {quizDataLoaded ? (
                <div style={{ width: "100%" }}>{DisplayQuizData(quizData)}</div>
              ) : (
                <p>{lang.common_loading[iHeal.userInfo.language]}</p>
              )}

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                disabled={!optionSelected}
                onClick={() => next()}
              >
                {lang.common_next[iHeal.userInfo.language]}
              </Button>
            </>
          ) : (
            <>
              {DisplayQuizResult(quizData, allOptions)}

              <br />

              <Button
                className="rounded-button rounded-ends full-width"
                style={{ color: pillarItem.pillarColourHex }}
                onClick={() => finishActivity()}
              >
                {lang.activities_finish[iHeal.userInfo.language]}
              </Button>
              <Button
                className="rounded-button rounded-ends full-width no-outline-button"
                onClick={() => restartActivity()}
              >
                {lang.activities_redo[iHeal.userInfo.language]}
              </Button>
            </>
          )}
        </div>

        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default MultiItemList;
