import React, { useState, useEffect, useContext } from "react";
import { Page } from "react-onsenui";

import GaEventTracker from "../../GaEventTracker.js";
import IhealContext from "../../Context/IhealContext.js";
import { getPillarTopics } from "../../GraphqlService.js";

import lang from "../../Assets/data/translations.json";
import Header from "../Common/Header";
import PillarIcon from "../Common/PillarIcon";

import BottomBar from "../Homescreen/BottomBar";
import ActivityQuizCard from "../Common/ActivityQuizCard";
import ActivityCard from "../Common/ActivityCard";
import Home from "../Homescreen/Home";
import Saved from "../Homescreen/Saved";
import Resources from "../Homescreen/Resources";
import DefaultActivity from "./DefaultActivity";
import ReadingActivity from "./ReadingActivity";

import "./Pillar.scss";

function Pillar(props) {
  // console.log( props );
  const { parentCallback, pillarItem } = props;

  const eventTracker = GaEventTracker(pillarItem.title_en);

  const iHeal = useContext(IhealContext);

  const [topicsLoaded, setTopicsLoaded] = useState(false);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    // https://stackoverflow.com/a/60907638/2762464
    let isMounted = true;

    try {
      getPillarTopics(pillarItem.id).then((res) => {
        if (isMounted) {
          setTopics(res || []);
          setTopicsLoaded(true);
        }
      });
    } catch (error) {
      console.error("Pillar getPillarTopics", error.message);
    }

    return () => {
      isMounted = false;
    };
  }, [pillarItem, iHeal]);

  function handleCallback(childData) {
    // console.log( {childData} );

    //if childata is just a string, then it navigates to a couple basic screen areas like home or default activity
    if (typeof childData === "string") {
      let screenName = childData;

      switch (screenName) {
        case "home":
          //todo: fix screen disappearing after multiple back and forths to homescreen
          //https://community.onsen.io/topic/3411/react-navigator-resetpage-causes-blank-screen
          //related to display: none being set on all page screens
          props.navigator.resetPage({
            component: Home,
          });
          break;
        case "saved":
          props.navigator.pushPage({ component: Saved, props: { key: "saved-screen" } });
          break;
        case "resources":
          props.navigator.pushPage({
            component: Resources,
            key: "resources-screen",
          });
          break;
        case "defaultActivity":
          eventTracker("Warm Up Activity", "", iHeal.userInfo.userid);
          props.navigator.pushPage({
            component: DefaultActivity,
            key: "default-activity-screen",
            props: { pillarItem: props.pillarItem, isDefault: true },
          });
          break;
        default:
          break;
      }
    } else {
      // if childata is a beefy object, then it's referring to an activity/topic
      eventTracker(
        "Topic",
        childData.activityInfo.title_en,
        iHeal.userInfo.userid,
      );
      if (childData.type === "defaultQuiz") {
        props.navigator.pushPage({
          component: DefaultActivity,
          key: "default-quiz",
          props: {
            type: childData.type,
            pillarItem: childData.pillarItem,
            activityId: childData.activityInfo.id,
          },
        });
      } else {
        props.navigator.pushPage({
          component: ReadingActivity,
          key: "reading-activity",
          props: {
            type: childData.type,
            pillarItem: childData.pillarItem,
            activityId: childData.activityInfo.id,
          },
        });
      }
    }
  }

  function displayTopics(topics) {
    return (
      <>
        {topics &&
          topics.map((item) => {
            return (
              <ActivityCard
                key={item.id}
                title={item[`title_${iHeal.userInfo.language}`]}
                typeHandle={item.typeHandle}
                icon={item.articleIcon[0].url}
                color={pillarItem.pillarColourHex}
                parentCallback={handleCallback.bind(this)}
                pillarItem={pillarItem}
                activityInfo={item}
              />
            );
          })}
      </>
    );
  }

  const renderToolbar = () => <Header type="back" />;
  const renderBottomToolbar = () => (
    <BottomBar parentCallback={handleCallback.bind(this)} />
  );

  return (
    <Page
      className={`pillar-screen ${pillarItem.slug}`}
      renderToolbar={renderToolbar}
      renderBottomToolbar={renderBottomToolbar}
    >
      {/* <Toolbar className='header-container'>
        <div className="left">
          <BackButton onClick={() => props.navigator.resetPage({component: Home}, {animation: 'fade', })}/>
        </div>
      </Toolbar> */}

      <div className="flexbox">
        <div className="flexside flex-5"></div>
        <div className="flexside flex-90 flex-left">
          <div className="pillar-icon">
            <PillarIcon slug={pillarItem.slug} />
          </div>

          <div className="center-text pillar-title">
            {pillarItem["title_" + iHeal.userInfo.language]}
          </div>

          <div
            className="pillar-desc"
            dangerouslySetInnerHTML={{
              __html: pillarItem["description_" + iHeal.userInfo.language],
            }}
          />

          {pillarItem.activityTitle_en === null ? (
            ""
          ) : (
            <ActivityQuizCard
              parentCallback={handleCallback.bind(this)}
              time={`${
                pillarItem[`activityDuration_${iHeal.userInfo.language}`]
              } ${lang.activities_minutes[iHeal.userInfo.language]}`}
              title={pillarItem[`activityTitle_${iHeal.userInfo.language}`]}
              color={pillarItem.pillarColourHex}
            />
          )}
          {/* <ActivityCard parentCallback={this.handleCallback.bind(this)} time={"1-2mins"} title={"How are you doing with basic needs?"} color={this.props.pillarColor}/> */}

          <div className="full-width">
            <h2 className="pillar-subtitle">
              {lang.pillar_topics[iHeal.userInfo.language]}
            </h2>
            <p>
              {pillarItem[`topicsSubheading_${iHeal.userInfo.language}`] !==
              null
                ? pillarItem[`topicsSubheading_${iHeal.userInfo.language}`]
                : iHeal.userInfo.language === "fr"
                  ? `Sélectionnez le sujet que vous souhaitez explorer ${pillarItem.title_fr}`
                  : `Choose a topic to explore more about ${pillarItem.title_en}`}
            </p>

            {topicsLoaded ? (
              <div>{displayTopics(topics)}</div>
            ) : (
              <p>{lang.common_loading[iHeal.userInfo.language]}</p>
            )}
          </div>
        </div>
        <div className="flexside flex-5"></div>
      </div>
    </Page>
  );
}
export default Pillar;
