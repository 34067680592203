import React from "react";
import ReactGA from "react-ga4";
import { Navigator } from "react-onsenui";

import IhealProvider from "./Context/IhealProvider";

// import Loading from './Components/Onboarding/Loading';
import Login from "./Components/Onboarding/Login";

// import FirstPage from './Components/FirstPage';
// import LockScreen from './Components/LockScreen';

import "./App.scss";

// Google Analytics tracking
// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics.html
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
ReactGA.send("pageview");

let keyIndex = 0;

export default class App extends React.Component {
  constructor(props) {
    super(props);

    localStorage.setItem("todaysQuote", "");
    localStorage.setItem("todaysQuoteId", "");
    localStorage.setItem("todaysQuoteSaved", "");
    localStorage.setItem("pillarCollection", "");
    localStorage.setItem("location", "");
    localStorage.setItem("language", "");
    localStorage.setItem("userType", "");

    // var storedOnboardedUser = false;
    // var storedpassword = "";
    // var storedUsername = "";
    // var loading = true;

    // if (localStorage.getItem('onboardedUser')) {
    //   storedOnboardedUser = (localStorage.getItem('onboardedUser') === 'true');
    //   loading = false;
    // }

    // if (localStorage.getItem('password')) {
    //   storedpassword = localStorage.getItem('password');
    // }

    // if (localStorage.getItem('username')) {
    //   storedUsername = localStorage.getItem('username');
    // }

    this.state = {
      // onboardedUser: storedOnboardedUser,
      // password: storedpassword,
      // username: storedUsername,
      // isLoading: loading
    };
  }

  renderPage(route, navigator) {
    const Component = route.component; // make sure Component is capitalized
    const key = keyIndex++; // get a unique key and increment key index
    // console.log("NEW KEY MADE: " + key + "\nComponent: " + route.component.name);
    // console.log( route.props );

    //we assign an incrementing key as otherwise resetpage function in navigator causes app to crash
    //components are usually pushed with a custom key ie
    // navigator.resetPage({component: Pillar, key: 'pillar-screen', props: {pillarName: pillarName, pillarIcon: pillarIcon, pillarColor: pillarColor}})
    //tho that key gets replaced by the incrementing key, ran out of time to go back and remove all the custom keys on pushpage()s

    return <Component key={key} navigator={navigator} {...route.props} />;
  }

  //if loading is true, then we show the loading screen, otherwise we should show a login page or language page depending on if returning/first time user
  //currently loading is not hooked up to any axios request and login page hasn't been made yet -> can just duplicate signup page and remove couple elements
  render() {
    // if(this.state.isLoading){
    return (
      <IhealProvider>
        <Navigator
          animation="lift"
          initialRoute={{
            component: Login,
            key: "login-screen",
            props: {
              onboardedUser: false,
              // password: this.state.password
            },
          }}
          renderPage={this.renderPage}
        />
      </IhealProvider>
    );
    // }
    //first time path if user has not completed onboarding, replace with Langugae page
    // else if(this.state.onboardedUser === false){
    //   return (
    //     <Navigator animation="lift"
    //       initialRoute={{
    //         component: FirstPage,
    //         key: 'firstpage',
    //         props: {
    //           onboardedUser: this.state.onboardedUser,
    //           password: this.state.password
    //         }}}
    //       renderPage={this.renderPage}
    //     />
    //   );
    // }
    // else{
    // //replace with login page
    // return (
    //   <Navigator animation="lift"
    //   initialRoute={{
    //     component: LockScreen,
    //     key: 'lock-screen',
    //     props: {
    //       onboardedUser: this.state.onboardedUser,
    //       password: this.state.password
    //     }}}
    //   renderPage={this.renderPage}
    // />
    // );
    // }
  }
}
