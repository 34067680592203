import React, { useState, useContext, useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import IhealContext from "../../Context/IhealContext.js";

import lang from "../../Assets/data/translations.json";
import { getExitText } from "../../GraphqlService.js";

export default function OnboardingTour(props) {
  const iHeal = useContext(IhealContext);

  const [showTour, setShowTour] = useState(iHeal.userInfo.showTour);
  const [exitText, setExitText] = useState(
    lang.onboarding_footerExit[iHeal.userInfo.language],
  );

  useEffect(() => {
    getExitText("onboardingTourText", iHeal.userInfo.language)
      .then((res) => {
        setExitText(res);
      })
      .catch((error) => {
        console.error("OnboardingTour getExitText", error.message);
      });
  }, [iHeal.userInfo.language]);

  // onboarding steps using Joyride https://docs.react-joyride.com/
  let steps = [
    {
      target: "#bars",
      content: lang.onboarding_accountIcon[iHeal.userInfo.language],
    },
    {
      target: ".footer-home",
      content: lang.onboarding_footerHome[iHeal.userInfo.language],
    },
    {
      target: ".footer-saved",
      content: lang.onboarding_footerSaved[iHeal.userInfo.language],
    },
    {
      target: ".footer-exit",
      content: exitText,
    },
  ];
  // https://docs.react-joyride.com/props
  let locale = {
    last: lang.common_last[iHeal.userInfo.language],
    next: lang.common_next[iHeal.userInfo.language],
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setShowTour(false);
      iHeal.userInfo.showTour = false;
    }
  };

  return (
    <>
      {showTour ? (
        <Joyride
          callback={handleJoyrideCallback}
          steps={steps}
          locale={locale}
          continuous={true}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          hideBackButton={true}
          hideCloseButton={true}
        />
      ) : (
        ""
      )}
    </>
  );
}
